import React, { useState } from 'react';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, CardFooter, Button } from 'reactstrap';
import IconDownload from '@assets/icons/download.svg';
import IconCopy from '@assets/icons/copy.svg';
import SPKPdf from '@helpers/pdf/SPKPdf';
import SPKText from '@helpers/text/SPKText';
import CopyToClipboard from 'react-copy-to-clipboard';
import OverridePriceIcon from '@assets/icons/override-price.svg';
import { Tooltip } from 'react-tooltip';

function BookingDetailSettlementWorkshop(props) {
  const { data, detailData, title, id, onResultNotification, bookingType, takeRateOtx } = props;

  const [isLoading, setLoading] = useState(false);

  const spkText = SPKText.create(data, { ...detailData, take_rate_otoklix: takeRateOtx });

  async function getEstimatedPrice() {
    setLoading(true);
    SPKPdf.create(data, { ...detailData, take_rate_otoklix: takeRateOtx });
    setLoading(false);
  }

  const handleCopyClipBoard = () => {
    handleResultNotification(true, 'success', 'SPK Bengkel berhasil disalin', true);
  };

  const handleResultNotification = (status = false, type = '', message = '', without_reload = false) => {
    const notification = {
      status: status,
      type: type,
      message: message,
      without_reload: without_reload,
    };

    onResultNotification(notification);
  };

  const packages = detailData?.items?.packages;
  const products = detailData?.items?.products;
  const services = detailData?.items?.services;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">{title}</CardHeader>
        <CardBody className="pb-0">
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12}>
              <Row>
                <Col lg={4} sm={12} className="col-summary">
                  <span className="detail-group"></span>
                </Col>
                <Col lg={3} sm={12} className="col-summary">
                  <span className="detail-group price">{'Harga Jual Bengkel'}</span>
                </Col>
                <Col lg={2} sm={12} className="col-summary">
                  <span className="detail-group price">{'Rate'}</span>
                </Col>
                <Col lg={3} sm={12} className="col-summary">
                  {''}
                </Col>
              </Row>
            </Col>
          </FormGroup>

          {packages?.package_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Paket</span>
                  </Col>
                </Row>
              </Col>
              {bookingType === 'b2c' ? (
                <>
                  {map(packages?.package_items, (item) => {
                    return (
                      <Col lg={12} sm={12}>
                        <Row>
                          <Col lg={4} sm={12} className="col-summary">
                            <span className="detail-group">{item?.name}</span>
                          </Col>
                          <Col lg={3} sm={12} className="col-summary" />
                          <Col lg={2} sm={12} className="col-summary" />
                          <Col lg={3} sm={12} className="col-summary">
                            <span className="price detail-group">{'IDR ' + Helper.formatMoney(item?.price ?? 0)}</span>
                          </Col>
                        </Row>
                        {map(item?.item_details, (subitem) => {
                          return (
                            <>
                              <Row>
                                <Col lg={4} sm={4} className="col-summary">
                                  <>
                                    <span className="detail-group-item-name">
                                      {subitem?.name}
                                      {subitem?.is_fbo && <span className="fbo-badge">FBO</span>}
                                      {subitem?.is_custom && <span className="custom-badge">Custom</span>}
                                    </span>
                                    <span className="detail-group-item-qty">{`${
                                      subitem?.qty
                                    } x IDR ${Helper.formatMoney(subitem?.unit_price ?? 0)}`}</span>
                                  </>
                                </Col>
                                <Col lg={3} sm={12} className="col-summary">
                                  <span className="detail-group-item-name price">
                                    {'IDR ' + Helper.formatMoney(subitem?.total_workshop_price ?? 0)}
                                  </span>
                                </Col>
                                <Col lg={2} sm={12} className="col-summary">
                                  <span className="detail-group-item-name grand-total price">
                                    {`${subitem?.take_rate ?? 0}%`}
                                  </span>
                                </Col>
                                <Col lg={3} sm={12} className="col-summary">
                                  <span className="price detail-group-item-name">
                                    {'IDR ' + Helper.formatMoney(subitem?.price ?? 0)}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <Row>
                          <Col lg={4} sm={12} className="col-summary">
                            <span className="detail-group">{'Subtotal Paket'}</span>
                          </Col>
                          <Col lg={3} sm={12} className="col-summary" />
                          <Col lg={2} sm={12} className="col-summary" />
                          <Col lg={3} sm={12} className="col-summary">
                            <span className="price detail-group">
                              {'IDR ' + Helper.formatMoney(item?.subtotal ?? 0)}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              ) : (
                <>
                  {map(packages?.package_items, (item) => {
                    return (
                      <Col lg={12} sm={12}>
                        <Row>
                          <Col lg={4} sm={12} className="col-summary">
                            <span className="detail-group">{item?.name}</span>
                          </Col>
                          <Col lg={3} sm={12} className="col-summary" />
                          <Col lg={2} sm={12} className="col-summary" />
                          <Col lg={3} sm={12} className="col-summary">
                            <span className="price detail-group">{'IDR ' + Helper.formatMoney(item?.price ?? 0)}</span>
                          </Col>
                        </Row>
                        {map(item?.item_details, (subitem) => {
                          return (
                            <>
                              <Row>
                                <Col lg={4} sm={4} className="col-summary">
                                  <>
                                    <span className="detail-group-item-name">
                                      {subitem?.qty} x {subitem?.name}
                                      {subitem?.is_fbo && <span className="fbo-badge">FBO</span>}
                                      {subitem?.is_custom && <span className="custom-badge">Custom</span>}
                                    </span>
                                  </>
                                </Col>
                                <Col lg={3} sm={12} className="col-summary">
                                  <span className="detail-group-item-name price">
                                    {'IDR ' + Helper.formatMoney(subitem?.total_workshop_price ?? 0)}
                                  </span>
                                </Col>
                                <Col lg={2} sm={12} className="col-summary">
                                  <span className="detail-group-item-name grand-total price">
                                    {`${subitem?.take_rate ?? 0}%`}
                                  </span>
                                </Col>
                                <Col lg={3} sm={12} className="col-summary">
                                  <span className="price detail-group-item-name">
                                    {'IDR ' + Helper.formatMoney(subitem?.price ?? 0)}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                        <Row>
                          <Col className="col-summary">
                            {item?.item_details[0]?.note && (
                              <>
                                <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                <span className="remarks-item mt-0">
                                  {!item?.item_details[0]?.note ? '-' : item?.item_details[0]?.note.replace(/-/g, '‑')}
                                </span>
                              </>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} sm={12} className="col-summary">
                            <span className="detail-group">{'Subtotal Paket'}</span>
                          </Col>
                          <Col lg={3} sm={12} className="col-summary" />
                          <Col lg={2} sm={12} className="col-summary" />
                          <Col lg={3} sm={12} className="col-summary">
                            <span className="price detail-group">
                              {'IDR ' + Helper.formatMoney(item?.subtotal ?? 0)}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              )}
            </FormGroup>
          ) : null}

          {products?.product_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Produk</span>
                  </Col>
                </Row>
              </Col>

              <Col lg={12} sm={12}>
                {map(products?.product_items, (item, index) => {
                  return (
                    <>
                      <Row>
                        <Col lg={4} sm={4} className="col-summary">
                          <>
                            <span className="detail-group-item-name">
                              {item?.name}
                              {item?.is_fbo && <span className="fbo-badge">FBO</span>}
                              {item?.is_custom && <span className="custom-badge">Custom</span>}
                            </span>
                            <span className="detail-group-item-qty">
                              {`${item?.qty} x IDR  ${Helper.formatMoney(item?.unit_price ?? 0)}`}
                              {item?.is_overide_nett_price && (
                                <>
                                  <img
                                    id={`overrides-icon_${item?.name}_${index}`}
                                    src={OverridePriceIcon}
                                    alt=""
                                    width={12}
                                    className="ml-1"
                                  />
                                  <Tooltip
                                    anchorId={`overrides-icon_${item?.name}_${index}`}
                                    place="top"
                                    content="Price is overrided"
                                  />
                                </>
                              )}
                            </span>
                            {item?.remark && (
                              <>
                                <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                <span className="remarks-item mt-0">
                                  {!item?.remark ? '-' : item?.remark.replace(/-/g, '‑')}
                                </span>
                              </>
                            )}
                          </>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="detail-group-item-name price">
                            {'IDR ' + Helper.formatMoney(item?.total_workshop_price ?? 0)}
                          </span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group-item-name grand-total price">{`${item?.take_rate ?? 0}%`}</span>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="price detail-group-item-name">
                            {'IDR ' + Helper.formatMoney(item?.price ?? 0)}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>

              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="detail-group">Subtotal Produk</span>
                  </Col>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="price detail-group">{`IDR ${Helper.formatMoney(
                      products?.subtotal_product ?? 0
                    )}`}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null}

          {services?.service_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Jasa</span>
                  </Col>
                </Row>
              </Col>

              <Col lg={12} sm={12}>
                {map(services?.service_items, (item, index) => {
                  return (
                    <>
                      <Row>
                        <Col lg={4} sm={4} className="col-summary">
                          <>
                            <span className="detail-group-item-name">
                              {item?.name}
                              {item?.is_fbo && <span className="fbo-badge">FBO</span>}
                              {item?.is_custom && <span className="custom-badge">Custom</span>}
                            </span>
                            <span className="detail-group-item-qty">
                              {`${item?.qty} x IDR  ${Helper.formatMoney(item?.unit_price ?? 0)}`}
                              {item?.is_overide_nett_price && (
                                <>
                                  <img
                                    id={`overrides-icon_${item?.name}_${index}`}
                                    src={OverridePriceIcon}
                                    alt=""
                                    width={12}
                                    className="ml-1"
                                  />
                                  <Tooltip
                                    anchorId={`overrides-icon_${item?.name}_${index}`}
                                    place="top"
                                    content="Price is overrided"
                                  />
                                </>
                              )}
                            </span>
                            {item?.remark && (
                              <>
                                <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                <span className="remarks-item mt-0">
                                  {!item?.remark ? '-' : item?.remark.replace(/-/g, '‑')}
                                </span>
                              </>
                            )}
                          </>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="detail-group-item-name price">
                            {'IDR ' + Helper.formatMoney(item?.total_workshop_price ?? 0)}
                          </span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group-item-name grand-total price">{`${item?.take_rate ?? 0}%`}</span>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="price detail-group-item-name">
                            {'IDR ' + Helper.formatMoney(item?.price ?? 0)}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>

              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="detail-group">Subtotal Jasa</span>
                  </Col>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="price detail-group">{`IDR ${Helper.formatMoney(
                      services?.subtotal_service ?? 0
                    )}`}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null}

          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{'Subtotal'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price detail-group">{'IDR ' + Helper.formatMoney(detailData?.dpp ?? 0)}</span>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          {data?.workshop?.is_pkp ? (
            <FormGroup row>
              <Col lg={2} sm={12} className="pr-3">
                <Row>
                  <Col lg={12} sm={12} className="text-sm-left col-summary">
                    <span className="group">{'PPN'}</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={10} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="price detail-group">{'IDR ' + Helper.formatMoney(detailData?.ppn ?? 0)}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null}

          {/* {bookingType === 'b2b' ? (
            <FormGroup row>
              <Col lg={2} sm={12} className="pr-3">
                <Row>
                  <Col lg={12} sm={12} className="text-sm-left col-summary">
                    <span className="group">{'PPh 23'}</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={10} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="price detail-group">{'IDR ' + Helper.formatMoney(detailData.pph ?? 0)}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null} */}

          {!data?.workshop?.is_pkp ? (
            <span className="text-muted font-weight-bold font-italic">Workshop Belum PKP</span>
          ) : null}

          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col lg={4} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{'Total'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={8} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price detail-group">{'IDR ' + Helper.formatMoney(detailData?.amount ?? 0)}</span>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormGroup row className="my-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>

          <FormGroup row className="otx-take-rate rounded pt-2 pb-1">
            <Col lg={5} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{'Otoklix Take Rate'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={7} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className={takeRateOtx < 0 ? 'price detail-group discount-otopoints' : 'price detail-group'}>
                    {Helper.generateFinalMoney(takeRateOtx ?? 0)}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </CardBody>
        <CardFooter className="p-3">
          <Row>
            <Col lg={6} md={12} sm={12} className="mt-2">
              <Button color="purple" block onClick={getEstimatedPrice} disabled={isLoading}>
                <img src={IconDownload} className="mr-2 mb-1" />
                <span>Download SPK Bengkel</span>
              </Button>
            </Col>
            <Col lg={6} md={12} sm={12} className="mt-2">
              <CopyToClipboard onCopy={handleCopyClipBoard} text={spkText}>
                <Button color="purple" block>
                  <img src={IconCopy} className="mr-2 mb-1" />
                  <span>Salin SPK Bengkel</span>
                </Button>
              </CopyToClipboard>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailSettlementWorkshop;
