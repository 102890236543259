import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';
import moment from 'moment';

export default class ReceiptPdf {
  static print(content, bookingCode) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 100,
            style: {
              alignment: 'left',
              fontSize: 8,
            },
            margin: [40, 30, 0, 0],
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 70, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
        textCenter: {
          alignment: 'center',
        },
        textRight: {
          alignment: 'right',
        },
        fontSize10: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(`Receipt ${bookingCode ?? ''}`);
  }

  static create(item) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const totalItemsLength = item?.summary?.length ?? 0;
    const itemTypeSort = item?.summary.sort((a, b) =>
      a.item_type > b.item_type ? 1 : b.item_type > a.item_type ? -1 : 0
    );
    const itemsSort =
      itemTypeSort?.length > 0
        ? itemTypeSort.sort((a, b) =>
            a.type > b.type && a.booking_package_id == null && b.booking_package_id == null
              ? 1
              : b.type > a.type && a.booking_package_id == null && b.booking_package_id == null
              ? -1
              : 0
          )
        : [];
    let rowspan = 1;
    const idOccurrences = {};

    // Loop through the array and count occurrences of each booking_package_id
    itemsSort.forEach((item) => {
      const bookingPackageId = item.booking_package_id;
      if (bookingPackageId !== null) {
        if (idOccurrences[bookingPackageId]) {
          idOccurrences[bookingPackageId]++;
        } else {
          idOccurrences[bookingPackageId] = 1;
        }
      }
    });

    const idOccurrencesArray = Object.entries(idOccurrences).map(([id, count]) => ({ id: parseInt(id), count }));
    const booking = map(itemsSort, (subitem, subindex) => {
      const sequent_number = subindex + 1;
      let product_type =
        subitem?.item_type === 'package' ? 'package' : subitem?.type ? Helper.convertItemType(subitem.type) : '-';
      let service_desc = '';
      if (subitem?.item_type === 'package') {
        idOccurrencesArray.forEach((item) => {
          if (subitem?.booking_package_id === item.id) {
            rowspan = item.count;
          }
        });
        service_desc = [
          { text: subitem?.name },
          { text: subitem?.voucher_label ? ` (${subitem?.voucher_label})` : '', italics: !!subitem?.voucher_label },
          { text: subitem?.note ? '\nKeterangan : ' + (subitem?.note ?? '') : '' },
          { text: `\n${subitem?.quantity} x Rp ${Helper.formatMoney(subitem?.total_base_price)}` },
          { text: `\n\n${Helper.convertItemType(subitem.type)}` },
          { text: `\n${subitem?.item_desc}` },
        ];
      } else {
        rowspan = 1;
        service_desc = [
          { text: subitem?.name ?? '-' },
          { text: subitem?.voucher_label ? ` (${subitem?.voucher_label})` : '', italics: !!subitem?.voucher_label },
          { text: subitem?.remark ? '\nKeterangan : ' + (subitem?.remark ?? '') : '' },
          {
            text: `\n${subitem?.quantity} x Rp ${Helper.formatMoney(+subitem?.base_price ?? 0)}`,
          },
        ];
      }
      return [
        { text: sequent_number, style: 'textCenter' },
        {
          text:
            product_type?.length > 1 ? product_type?.charAt(0)?.toUpperCase() + product_type?.slice(1) : product_type,
          rowSpan: rowspan,
          alignment: 'center',
        },
        {
          text: service_desc,
        },
        { text: `Rp ${Helper.formatMoney(subitem?.discount)}`, style: 'textRight' },
        { text: `Rp ${Helper.formatMoney(subitem?.voucher)}`, style: 'textRight' },
        { text: `Rp ${Helper.formatMoney(subitem?.sub_total)}`, style: 'textRight' },
      ];
    });

    const bookingTotal = () => {
      let dataTotal = [
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
        ],
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: 'Total', alignment: 'right', colSpan: 2 },
          { text: '', border: [false] },
          { text: `Rp ${Helper?.formatMoney(item?.grand_total ?? 0)}`, alignment: 'right' },
        ],
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: 'DPP', alignment: 'right', colSpan: 2 },
          { text: '', border: [false] },
          { text: `Rp ${Helper?.formatMoney(item?.dpp ?? 0)}`, alignment: 'right' },
        ],
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: 'PPN', alignment: 'right', colSpan: 2 },
          { text: '', border: [false] },
          { text: `Rp ${Helper?.formatMoney(item?.ppn ?? 0)}`, alignment: 'right' },
        ],
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: 'PPH', alignment: 'right', colSpan: 2 },
          { text: '', border: [false] },
          { text: `Rp ${Helper?.formatMoney(item?.pph ?? 0)}`, alignment: 'right' },
        ],
        [
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: '', border: [false] },
          { text: 'Payment Amount', alignment: 'right', colSpan: 2 },
          { text: '', border: [false] },
          { text: `Rp ${Helper?.formatMoney(item?.payment_amount ?? 0)}`, alignment: 'right' },
        ],
      ];
      return dataTotal;
    };

    const content = [
      {
        table: {
          body: [
            ['No. Invoice  ', ':', item?.receipt_number],
            ['Booking Code', ':', item?.booking_code],
          ],
        },
        style: 'tableStyle',
        layout: 'noBorders',
        margin: [0, 0, 0, 16],
      },
      {
        table: {
          body: [
            [
              {
                table: {
                  widths: [75, 2, 120],
                  body: [
                    [{ text: 'Customer Detail', style: { bold: true } }, '', ''],
                    ['Name', ':', item?.customer_name ?? '-'],
                    ['Car Type', ':', item?.car_type ?? '-'],
                    ['License Plate', ':', item?.license_plate ?? '-'],
                    ['Contact Number', ':', item?.customer_phone ?? '-'],
                    ['KM Service', ':', item?.mileage ?? '-'],
                  ],
                },
                style: 'tableStyle',
                layout: 'noBorders',
                margin: [0, 0, 16, 0],
              },
              {
                table: {
                  widths: [80, 2, 180],
                  body: [
                    [{ text: 'Payment Detail', style: { bold: true } }, '', ''],
                    ['SPK No.', ':', item?.spk_number ?? '-'],
                    ['Payment Method', ':', item?.payment_method],
                    ['Order Completed', ':', item?.order_completed ?? '-'],
                  ],
                },
                style: 'tableStyle',
                layout: 'noBorders',
              },
            ],
          ],
        },
        style: 'tableStyle',
        layout: 'noBorders',
        margin: [0, 0, 0, 16],
      },
      { text: 'Service Detail', style: 'fontSize10', bold: 'true' },
      {
        table: {
          widths: [24, 50, 150, 70, 80, 85],
          body: [['No. ', 'Type', 'Service Description', 'Discount', 'Voucher', 'Subtotal'], ...booking],
        },
        style: 'tableStyle',
        margin: [0, 16],
      },
      {
        table: {
          widths: [24, 50, '*', 70, 80, 85],
          body: [...bookingTotal()],
        },
        unbreakable: true,
        style: 'tableStyle',
        margin: [0, 16],
      },
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                text:
                  'for transfer payment method, please make your payment to BCA PT. Oto Klix Indonesia: 0352-988-918',
                style: 'fontSize10',
              },
            ],
            [
              {
                text: '\nTim Finance \nPT Oto Klix Indonesia',
                style: 'fontSize10',
                alignment: 'center',
                margin: [0, 64],
              },
            ],
          ],
        },
        unbreakable: true,
        style: 'tableStyle',
        margin: [0, 16],
        layout: 'noBorders',
      },
    ];

    return content;
  }
}
