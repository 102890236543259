import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, FormGroup, Label, Col, Input, Button, FormFeedback } from 'reactstrap';
import Helper from '@helpers/Helper';
import InfoIcon from '@assets/icons/info.svg';

const OverridePriceModal = (props) => {
  const { isOpen, toggle, nettPrice, handleCustomerPriceChange, selectedCartItem } = props;

  const [isSplitedTax, setIsSplitedTax] = useState(false);
  const [overrideNettPrice, setOverrideNettPrice] = useState({
    formatIsMoney: false,
    formatMoney: nettPrice,
    formatMoneyString: `IDR ${Helper.formatMoney(nettPrice)}`,
    isNoPriceAvailable: nettPrice < 0 ? true : false,
    value: nettPrice,
  });
  const [errorNettPrice, setErrorNettPrice] = useState('');
  const [isInitialOverridePrice, setIsInitialOverridePrice] = useState(true);

  const handleChangeSplitedTaxCheckBox = () => setIsSplitedTax(!isSplitedTax);

  const cancel = () => {
    toggle();
  };

  const handleNettPriceChange = (value) => {
    var nettPriceValue =
      Helper.removeMoneyFormat(value < 0 ? 0 : value) > 99999999999
        ? 99999999999
        : Helper.removeMoneyFormat(value < 0 ? 0 : value);

    // nettPriceValue = isSplitedTax ? Helper.countDPP(nettPriceValue) : nettPriceValue;
    try {
      const nettprice = Helper.formatMoneyOnField(nettPriceValue, 'IDR ');

      setOverrideNettPrice({ ...nettprice, isNoPriceAvailable: value < 0 ? true : false });
    } catch {
      setOverrideNettPrice({
        formatIsMoney: false,
        formatMoney: value == '' ? NaN : nettPriceValue,
        formatMoneyString: value == '' ? '' : `IDR ${Helper.formatMoney(nettPriceValue)}`,
        isNoPriceAvailable: value < 0 ? true : false,
        value: value == '' ? NaN : nettPriceValue,
      });
    }

    if (Helper.removeMoneyFormat(value) > 99999999999) {
      setErrorNettPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    } else {
      setErrorNettPrice('');
    }
  };

  const handleSubmitOverride = (workshopId, productId, originalNettPrice, splitTax = false) => {
    let updatedPrice = overrideNettPrice;

    if (splitTax) {
      const calculatedDPP = Helper.countDPP(overrideNettPrice.formatMoney);
      updatedPrice = {
        ...overrideNettPrice,
        formatMoney: calculatedDPP,
        formatMoneyString: `IDR ${Helper.formatMoney(calculatedDPP)}`,
        value: calculatedDPP,
      };

      // Update the state with the new value
      // setOverrideNettPrice(updatedPrice);
    }

    setOverrideNettPrice(updatedPrice);

    // Use the updated value directly
    handleCustomerPriceChange(workshopId, productId, null, updatedPrice, null, originalNettPrice, splitTax, overrideNettPrice.formatMoney);
    setIsSplitedTax(false);
    toggle();
  };

  // Ensure the overrideNettPrice state is updated when nettPrice changes
  useEffect(() => {
    const nettPrice = !selectedCartItem?.isSplitedTax ? selectedCartItem?.nettPriceValue : selectedCartItem?.workshopPriceAfterTax
    setOverrideNettPrice({
      formatIsMoney: false,
      formatMoney: nettPrice,
      formatMoneyString: `IDR ${Helper.formatMoney(nettPrice)}`,
      isNoPriceAvailable: isNaN(nettPrice),
    });

    setIsSplitedTax(selectedCartItem?.isSplitedTax);
  }, [selectedCartItem]); // Listen for changes in nettPrice

  // useEffect(() => {
  //   handlePriceChange();
  // });

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered modal-form" backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Override Harga</div>
        <div className="content">
          <hr />
          <FormGroup row>
            <Label lg={3}>
              <img
                src={InfoIcon}
                alt=""
                className="mr-1 cursor-pointer"
                width={16}
                data-tooltip-id="info-split-tax"
                data-tooltip-content="Jika diaktifkan, maka operator mengisi Harga Jual Bengkel termasuk PPN lalu sistem akan menghitung DPP"
              />
              Split Tax Pricing
            </Label>
            <Col lg={9} style={{ padding: '6px 12px', display: 'flex', alignItems: 'center' }}>
              <Input
                id="split-tax-check"
                type="checkbox"
                onChange={handleChangeSplitedTaxCheckBox}
                checked={isSplitedTax}
              />
            </Col>
            <Label lg={3}>{isSplitedTax ? 'Harga Jual Bengkel Termasuk PPN' : 'Harga Jual Bengkel'}</Label>
            <Col lg={7} sm={11}>
              <Input
                id="nett-price-edit"
                className="input-field"
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/\D/g, '');

                  let formattedValue = '';

                  if (rawValue) {
                    formattedValue =
                      'IDR ' +
                      new Intl.NumberFormat('id-ID', {
                        style: 'decimal',
                        maximumFractionDigits: 0,
                      }).format(rawValue);
                  } else {
                    formattedValue = '';
                  }
                  handleNettPriceChange(formattedValue);
                  e.target.value = formattedValue;
                }}
                defaultValue={overrideNettPrice?.isNoPriceAvailable ? '' : overrideNettPrice.formatMoneyString}
                invalid={!!errorNettPrice}
              />
              <FormFeedback>{errorNettPrice ?? ''}</FormFeedback>
            </Col>
          </FormGroup>

          {isSplitedTax && (
            <FormGroup row>
              <Label lg={3}>Harga Jual Bengkel</Label>
              <Col lg={9} sm={12} className="d-flex align-items-center">
                <span>{`Rp. ${Helper.formatMoney(Helper.countDPP(overrideNettPrice?.formatMoney))}`}</span>
              </Col>
            </FormGroup>
          )}
        </div>
        <div className="action mb-3 mt-3">
          <Button id="item-edit-cancel" className="button-cancel" onClick={cancel}>
            Batalkan
          </Button>
          <Button
            id="item-edit-save"
            className="button-action"
            onClick={() =>
              handleSubmitOverride(
                selectedCartItem?.workshop_id,
                selectedCartItem?.product_id,
                selectedCartItem?.originalNettPrice,
                isSplitedTax
              )
            }
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OverridePriceModal;
