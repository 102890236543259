import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import Skeleton from '@components/loading/Skeleton';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import { Tooltip } from 'react-tooltip';
import Helper from '@helpers/Helper';

const BookingCreateDetailFleet = (props) => {
  const { summaryBooking, detailFleet, hasProduct, is_b2b, dataPromo, loadingCalculate, otopoints } = props;

  const requestedProducts = detailFleet?.settlement_customer?.items?.products?.product_items;
  const requestedPackages = detailFleet?.settlement_customer?.items?.packages?.package_items;
  const requestedServices = detailFleet?.settlement_customer?.items?.services?.service_items;
  let customRequestedProducts = [];
  let customRequestedServices = [];
  if (requestedProducts) {
    customRequestedProducts = Object.keys(requestedProducts)
      .filter((productKey) => requestedProducts[productKey]?.is_custom)
      .reduce((filtered, productKey) => {
        filtered[productKey] = requestedProducts[productKey];
        return filtered;
      }, {});
  }
  if (requestedServices) {
    customRequestedServices = Object.keys(requestedServices)
      .filter((serviceKey) => requestedServices[serviceKey].is_custom)
      .reduce((filtered, serviceKey) => {
        filtered[serviceKey] = requestedServices[serviceKey];
        return filtered;
      }, {});
  }

  const [dataDetailFleet, setDataDetailFleet] = useState({});
  const [emptyState, setEmptyState] = useState(false);

  const mappingDataFleet = (detailBooking) => {
    let dataBookingFleet = {
      groups: [
        {
          name: 'Paket',
          group_items: [],
        },
        {
          name: 'Produk',
          group_items: [],
        },
        {
          name: 'Jasa',
          group_items: [],
        },
        {
          name: 'Custom',
          group_items: [],
        },
      ],
    };

    detailBooking?.packages?.items?.forEach((item) => {
      if (item?.package_details !== undefined) {
        let groupItemsDetail = [];
        item?.package_details?.forEach((detailItem) => {
          groupItemsDetail.push({ name: detailItem?.name ?? '', qty: detailItem?.qty ?? 0, is_available: true });
        });
        dataBookingFleet.groups[0].group_items.push({
          name: item?.name ?? '',
          group_item_details: groupItemsDetail,
        });
      } else if (item?.products !== undefined) {
        item?.products?.forEach((detailItem) => {
          const isProduct = Helper.readIsProduct(detailItem?.product_type ?? '');
          const isService = Helper.readIsService(detailItem?.product_type ?? '');
          const index = isProduct ? 1 : isService ? 2 : null;
          if (index !== null) {
            if (dataBookingFleet?.groups[index]?.group_items?.length) {
              dataBookingFleet.groups[index].group_items[0].group_item_details.push({
                name: detailItem?.name ?? '',
                qty: detailItem?.qty ?? 0,
                is_available: true,
              });
            } else {
              dataBookingFleet.groups[index].group_items.push({
                group_item_details: [
                  {
                    name: detailItem?.name ?? '',
                    qty: detailItem?.qty ?? 0,
                    is_available: true,
                  },
                ],
              });
            }
          }
        });
      } else if (item?.custom_products !== undefined) {
        item?.custom_products?.forEach((detailItem) => {
          const isProduct = Helper.readIsProduct(detailItem?.product_type ?? '');
          const isService = Helper.readIsService(detailItem?.product_type ?? '');
          const categoryName = isProduct ? 'Produk' : isService ? 'Jasa' : null;
          if (categoryName !== null) {
            const index = findIndex(dataBookingFleet?.groups[3]?.group_items, (item) => {
              return item?.name === categoryName;
            });
            if (index >= 0) {
              dataBookingFleet.groups[3].group_items[index].group_item_details.push({
                name: detailItem?.name ?? '',
                qty: detailItem?.qty ?? 0,
                is_available: true,
              });
            } else {
              dataBookingFleet.groups[3].group_items.push({
                name: categoryName,
                group_item_details: [
                  {
                    name: detailItem?.name ?? '',
                    qty: detailItem?.qty ?? 0,
                    is_available: true,
                  },
                ],
              });
            }
          }
        });
      }
    });

    setDataDetailFleet(dataBookingFleet);
  };

  useEffect(() => {
    if (dataDetailFleet?.groups) {
      dataDetailFleet?.groups[0]?.group_items?.length < 1 &&
        dataDetailFleet?.groups[1]?.group_items?.length < 1 &&
        dataDetailFleet?.groups[2]?.group_items?.length < 1 &&
        dataDetailFleet?.groups[3]?.group_items?.length < 1 &&
        setEmptyState(true);
    }
  }, [dataDetailFleet]);

  useEffect(() => {
    mappingDataFleet(detailFleet);
  }, []);

  return (
    <>
      <Card>
        <CardHeader className="text-white mt-0">Estimasi Harga</CardHeader>
        <CardBody>
          {!hasProduct ? <span>-</span> : null}
          <>
            <FormGroup row className="mb-0">
              <Col lg={2} sm={2} className="pr-4">
                <Row>
                  <Col lg={12} sm={12} className="text-sm-right">
                    <span className="label">Service</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={10} sm={10}>
                {summaryBooking?.items?.packages?.length > 0 ? (
                  <FormGroup row>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="detail-group">Paket</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Mark Up'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Disc'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Voucher'}</span>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          {''}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} sm={12}>
                      {map(summaryBooking?.items?.packages, (item, index) => {
                        return (
                          <>
                            <Row>
                              <Col lg={3} sm={12} className="col-summary">
                                <span className="detail-group">{item?.name ?? '-'}</span>
                              </Col>
                              <Col lg={2} sm={12} className="col-summary">
                                <span className="detail-group-item-name grand-total price">{`${0}%`}</span>
                              </Col>
                              <Col lg={2} sm={12} className="col-summary">
                                <span className="detail-group-item-name discount-otopoints price">{`${0}%`}</span>
                              </Col>
                              <Col lg={2} sm={12} className="col-summary">
                                <span className="detail-group-item-name discount-otopoints price min-h-16" />
                                <span
                                  id={`promo-code-package-${item?.details?.[0]?.promo_code}-${index}`}
                                  className="detail-group-item-name discount-otopoints price text-truncate"
                                >
                                  {item?.details?.[0]?.promo_code && `${item?.details?.[0]?.promo_code}`}
                                </span>
                                <Tooltip
                                  anchorId={`promo-code-package-${item?.details?.[0]?.promo_code}-${index}`}
                                  place="top"
                                  content={item?.details?.[0]?.promo_code}
                                />
                                <span className="detail-group-item-name discount-otopoints price">
                                  {item?.details?.[0]?.voucher_percentage &&
                                    `${item?.details?.[0]?.voucher_percentage} %`}
                                </span>
                              </Col>
                              <Col lg={3} sm={12} className="col-summary">
                                {loadingCalculate ? (
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                ) : (
                                  <>
                                    <span className="price detail-group">
                                      {'IDR ' + Helper.formatMoney(item?.subtotal ?? 0)}
                                    </span>
                                    <span className="price text-danger">
                                      {'- IDR ' +
                                        Helper.formatMoney(
                                          item?.details?.[0]?.total_voucher_amount ?? 0,
                                          0,
                                          ',',
                                          '.',
                                          true,
                                          true
                                        )}
                                    </span>
                                  </>
                                )}
                              </Col>
                            </Row>
                            {map(item?.details, (subitem) => {
                              return (
                                <Row>
                                  <Col lg={3} sm={5} className="col-summary">
                                    <>
                                      <span className="detail-group-item-name">
                                        {!subitem?.name ? '-' : subitem?.name}
                                      </span>
                                      {loadingCalculate ? (
                                        <Skeleton width={100} height={14} />
                                      ) : (
                                        <span className="detail-group-item-qty">{`${
                                          subitem?.qty
                                        } x IDR  ${Helper.formatMoney(subitem?.updated_unit_price ?? 0)}`}</span>
                                      )}
                                    </>
                                  </Col>
                                  <Col lg={2} sm={12} className="col-summary">
                                    {''}
                                  </Col>
                                  <Col lg={2} sm={12} className="col-summary">
                                    {''}
                                  </Col>
                                  <Col lg={2} sm={12} className="col-summary">
                                    {''}
                                  </Col>
                                  <Col lg={3} sm={12} className="col-summary">
                                    {loadingCalculate ? (
                                      <>
                                        <div className="d-flex justify-content-end">
                                          <Skeleton width={100} height={14} />
                                        </div>
                                        <div className="d-flex justify-content-end">
                                          <Skeleton width={100} height={14} />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <span className="price detail-group-item-name">
                                          {'IDR ' +
                                            Helper.formatMoney(subitem?.updated_price ?? 0, 0, ',', '.', true, true)}
                                        </span>
                                        {subitem?.updated_discount > 0 && (
                                          <span className="price text-danger">
                                            {'- IDR ' +
                                              Helper.formatMoney(
                                                subitem?.updated_discount ?? 0,
                                                0,
                                                ',',
                                                '.',
                                                true,
                                                true
                                              )}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              );
                            })}
                          </>
                        );
                      })}
                    </Col>
                  </FormGroup>
                ) : null}

                {summaryBooking?.items?.products.length > 0 ? (
                  <FormGroup row>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="detail-group">Produk</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Mark Up'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Disc'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Disc'}</span>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          {''}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} sm={12}>
                      {map(summaryBooking?.items?.products, (item) => {
                        return (
                          <Row>
                            <Col lg={3} sm={5} className="col-summary">
                              <>
                                <span className="detail-group-item-name">{!item?.name ? '-' : item?.name}</span>
                                {loadingCalculate ? (
                                  <Skeleton width={100} height={14} />
                                ) : (
                                  <span className="detail-group-item-qty">{`${item?.qty} x IDR  ${Helper.formatMoney(
                                    item?.updated_unit_price ?? 0
                                  )}`}</span>
                                )}
                                {item?.remarks && (
                                  <>
                                    <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                    <span className="remarks-item mt-0">
                                      {!item?.remarks ? '-' : item?.remarks.replace(/-/g, '‑')}
                                    </span>
                                  </>
                                )}
                              </>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name grand-total price">{`${
                                item?.markup ?? 0
                              }%`}</span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span className="detail-group-item-name discount-otopoints price">
                                {`${item?.discount ?? 0}%`}
                              </span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span
                                id={`promo-code-product-${item?.promo_code}-${item?.product_id}`}
                                className="detail-group-item-name discount-otopoints price text-truncate"
                              >
                                {item?.promo_code && `${item?.promo_code}`}
                              </span>
                              <Tooltip
                                anchorId={`promo-code-product-${item?.promo_code}-${item?.product_id}`}
                                place="top"
                                content={item?.promo_code}
                              />
                              <span className="detail-group-item-name discount-otopoints price">
                                {item?.voucher_percentage && `${item?.voucher_percentage} %`}
                              </span>
                            </Col>
                            <Col lg={3} sm={12} className="col-summary">
                              {loadingCalculate ? (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span className="price detail-group-item-name">
                                    {'IDR ' + Helper.formatMoney(item?.updated_price ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                  <span className="price text-danger">
                                    {'- IDR ' +
                                      Helper.formatMoney(item?.updated_discount ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                  <span className="price text-danger">
                                    {'- IDR ' +
                                      Helper.formatMoney(item?.updated_voucher_discount ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                </>
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={6} sm={12} className="col-summary">
                          <span className="detail-group">Subtotal Produk</span>
                        </Col>
                        <Col lg={6} sm={12} className="col-summary text-right font-weight-bold">
                          {loadingCalculate ? (
                            <Skeleton width={100} height={14} />
                          ) : (
                            `IDR ${Helper.formatMoney(summaryBooking?.items?.subtotal_product ?? 0)}`
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                ) : null}

                {summaryBooking?.items?.services.length > 0 ? (
                  <FormGroup row>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="detail-group">Jasa</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Mark Up'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Disc'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Disc'}</span>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          {''}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} sm={12}>
                      {map(summaryBooking?.items?.services, (item) => {
                        return (
                          <Row>
                            <Col lg={3} sm={5} className="col-summary">
                              <>
                                <span className="detail-group-item-name">{!item?.name ? '-' : item?.name}</span>
                                {loadingCalculate ? (
                                  <Skeleton width={100} height={14} />
                                ) : (
                                  <span className="detail-group-item-qty">{`${item?.qty} x IDR  ${Helper.formatMoney(
                                    item?.updated_unit_price ?? 0
                                  )}`}</span>
                                )}
                                {item?.remarks && (
                                  <>
                                    <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                    <span className="remarks-item mt-0">
                                      {!item?.remarks ? '-' : item?.remarks.replace(/-/g, '‑')}
                                    </span>
                                  </>
                                )}
                              </>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name grand-total price">{`${
                                item?.markup ?? 0
                              }%`}</span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span className="detail-group-item-name discount-otopoints price">
                                {`${item?.discount ?? 0}%`}
                              </span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span
                                id={`promo-code-service-${item?.promo_code}-${item?.product_id}`}
                                className="detail-group-item-name discount-otopoints price text-truncate"
                              >
                                {item?.promo_code && `${item?.promo_code}`}
                              </span>
                              <Tooltip
                                anchorId={`promo-code-service-${item?.promo_code}-${item?.product_id}`}
                                place="top"
                                content={item?.promo_code}
                              />
                              <span className="detail-group-item-name discount-otopoints price">
                                {item?.voucher_percentage && `${item?.voucher_percentage} %`}
                              </span>
                            </Col>
                            <Col lg={3} sm={12} className="col-summary">
                              {loadingCalculate ? (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span className="price detail-group-item-name">
                                    {'IDR ' + Helper.formatMoney(item?.updated_price ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                  <span className="price text-danger">
                                    {'- IDR ' +
                                      Helper.formatMoney(item?.updated_discount ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                  <span className="price text-danger">
                                    {'- IDR ' +
                                      Helper.formatMoney(item?.updated_voucher_discount ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                </>
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={6} sm={12} className="col-summary">
                          <span className="detail-group">Subtotal Jasa</span>
                        </Col>
                        <Col lg={6} sm={12} className="col-summary text-right font-weight-bold">
                          {loadingCalculate ? (
                            <Skeleton width={100} height={14} />
                          ) : (
                            `IDR ${Helper.formatMoney(summaryBooking?.items?.subtotal_service ?? 0)}`
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                ) : null}
              </Col>
              <Col lg={12} sm={12} className="col-summary">
                <div className="divider"></div>
              </Col>
            </FormGroup>

            <FormGroup row className="mb-0">
              <Col lg={2} sm={2} className="pr-4">
                <Row>
                  <Col lg={12} sm={12} className="text-sm-right">
                    <dt>{is_b2b ? 'Total Diskon' : 'Kode Promo'}</dt>
                  </Col>
                </Row>
              </Col>
              <Col lg={10} sm={10}>
                <Row>
                  <Col lg={6} sm={6} className="col-summary">
                    {loadingCalculate && summaryBooking?.discount?.promo ? (
                      <Skeleton width={100} height={14} />
                    ) : (
                      <span>{dataPromo?.promo ?? ''}</span>
                    )}
                  </Col>
                  <Col lg={6} sm={6} className="col-summary">
                    {loadingCalculate ? (
                      <div className="d-flex justify-content-end">
                        <Skeleton width={100} height={14} />
                      </div>
                    ) : (
                      <span className="price discount-otopoints">
                        {`- IDR ${Helper.formatMoney(Math.floor(dataPromo?.amount))}`}
                      </span>
                    )}
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col lg={2} sm={2} className="pr-4">
                {!is_b2b && (
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-right">
                      <dt>Otopoints</dt>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col lg={10} sm={10}>
                <Row>
                  <Col lg={6} sm={6} className="col-summary">
                    <span>{''}</span>
                  </Col>
                  {!is_b2b && (
                    <Col lg={6} sm={6} className="col-summary">
                      {loadingCalculate ? (
                        <div className="d-flex justify-content-end">
                          <Skeleton width={100} height={14} />
                        </div>
                      ) : (
                        <span className="price discount-otopoints">
                          {'- IDR ' + Helper.formatMoney(otopoints?.used ?? 0, 0, ',', '.', true)}
                        </span>
                      )}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <div className="divider"></div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </>

          <FormGroup row className="mb-0">
            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right col-summary">
                  <span>DPP</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  {loadingCalculate ? (
                    <div className="d-flex justify-content-end">
                      <Skeleton width={100} height={14} />
                    </div>
                  ) : (
                    <span className="price">{'IDR ' + Helper.formatMoney(summaryBooking?.items?.dpp ?? 0)}</span>
                  )}
                </Col>
              </Row>
            </Col>

            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right col-summary">
                  <span>PPN </span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  {loadingCalculate ? (
                    <div className="d-flex justify-content-end">
                      <Skeleton width={100} height={14} />
                    </div>
                  ) : (
                    <span className="price">{'IDR ' + Helper.formatMoney(summaryBooking?.items?.ppn ?? 0)}</span>
                  )}
                </Col>
              </Row>
            </Col>

            {is_b2b ? (
              <>
                <Col lg={2} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-right col-summary">
                      <span>PPH </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={10} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      {loadingCalculate ? (
                        <div className="d-flex justify-content-end">
                          <Skeleton width={100} height={14} />
                        </div>
                      ) : (
                        <span className="price discount-otopoints">
                          {'- IDR ' + Helper.formatMoney(summaryBooking?.items?.pph ?? 0)}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}

            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right col-summary">
                  <span>Amount</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  {loadingCalculate ? (
                    <div className="d-flex justify-content-end">
                      <Skeleton width={100} height={14} />
                    </div>
                  ) : (
                    <span className="price grand-total">
                      {'IDR ' + Helper.formatMoney(summaryBooking?.items?.amount ?? 0)}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="text-white mt-0">Permintaan Servis</CardHeader>
        <CardBody>
          <div
            dangerouslySetInnerHTML={{
              __html: detailFleet?.service_demand?.replace(/\n/g, '<br />') ?? '-',
            }}
          />
          {requestedPackages && requestedPackages.length > 0 ? (
            <FormGroup row className="mb-3">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Paket</span>
                  </Col>
                </Row>
              </Col>
              {map(requestedPackages, (item) => {
                return (
                  <Col lg={12} sm={12}>
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span className="detail-group-service">ITEM NAME</span>
                        </Col>
                      </Row>

                      <>
                        <Row>
                          <Col lg={12} sm={12} className="col-summary">
                            <>
                              <span
                                className={
                                  detailData?.is_approve
                                    ? 'detail-group-item-name'
                                    : 'detail-group-item-name text-danger'
                                }
                              >
                                {`1 x DETAIL NAME`}
                                {detailData?.note && (
                                  <img
                                    src={WarningIcon}
                                    width={14}
                                    className="ml-1 pointer-cursor"
                                    onClick={() => handleClickNote(detailData?.note)}
                                  />
                                )}
                              </span>
                            </>
                          </Col>
                        </Row>
                      </>
                    </>
                  </Col>
                );
              })}
            </FormGroup>
          ) : null}

          {requestedProducts && requestedProducts?.length > 0 ? (
            <FormGroup row className="mb-3">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Items</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} sm={12}>
                {requestedProducts?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Produk</span>
                    </Col>
                  </Row>
                ) : null}
                {map(requestedProducts, (item) => {
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>

              <Col lg={12} sm={12}>
                {requestedServices && requestedServices?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Jasa</span>
                    </Col>
                  </Row>
                ) : null}
                {map(requestedServices, (item) => {
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </FormGroup>
          ) : null}

          {customRequestedProducts?.length > 0 || customRequestedServices?.length > 0 ? (
            <FormGroup row className="mb-3">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Custom</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} sm={12}>
                {customRequestedProducts?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Produk</span>
                    </Col>
                  </Row>
                ) : null}
                {map(customRequestedProducts, (item) => {
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>

              <Col lg={12} sm={12}>
                {customRequestedServices?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Jasa</span>
                    </Col>
                  </Row>
                ) : null}
                {map(customRequestedServices, (item) => {
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </FormGroup>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
};

export default BookingCreateDetailFleet;
