import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';

export default class OfferingLetterPdf {
  static print(content, bookingCode) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 180,
            style: {
              alignment: 'center',
              fontSize: 8,
            },
            margin: [0, 30, 0, 0],
          },
          {
            margin: [40, 0, 40, 0],
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 10,
                x2: 515,
                y2: 10,
                lineWidth: 1.7,
                lineColor: '#cc4200',
              },
            ],
          },
          {
            text: 'PT. OTO KLIX INDONESIA',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 10, 40, 0],
            bold: true,
          },
          {
            text: 'Jl. Terusan Sinabung No.1',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
          },
          {
            text: 'Grogol Selatan, Kebayoran Baru',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
          {
            text: 'Kota Jakarta Selatan, DKI Jakarta 12220. Indonesia',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 170, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          margin: [0, 0, 0, 0],
        },
        tableDash: {
          margin: [-50, 5, 0, -50],
        },
        tableSpace: {
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
        textRight: {
          alignment: 'right',
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(`Surat Penawaran ${bookingCode ?? ''}`);
  }

  static create(dataBooking, dataSummary, source = 'booking-detail') {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const splitTime = dataBooking?.booking_time ? dataBooking?.booking_time.split('.') : [];
    const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    let bookingSchedule = dataBooking?.booking_date ?? '';
    bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = dataBooking?.user_car?.car_details?.car_model?.brand?.name
      ? dataBooking?.user_car?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.car_model?.model_name
      ? dataBooking?.user_car?.car_details?.car_model?.model_name + ' '
      : '';
    carType += dataBooking?.user_car?.license_plate ? ' - ' + dataBooking?.user_car?.license_plate : '';

    const bookingList = (data) => {
      let rowspan = 1;
      const idOccurrences = {};
      const itemTypeSort =
        data?.items.length > 0
          ? [...data?.items].sort((a, b) => (a.item_type > b.item_type ? 1 : b.item_type > a.item_type ? -1 : 0))
          : [];
      const itemsSort =
        itemTypeSort?.length > 0
          ? itemTypeSort.sort((a, b) =>
              a.type > b.type && a.booking_package_id == null && b.booking_package_id == null
                ? 1
                : b.type > a.type && a.booking_package_id == null && b.booking_package_id == null
                ? -1
                : 0
            )
          : [];
      // Loop through the array and count occurrences of each booking_package_id
      itemsSort.forEach((item) => {
        const bookingPackageId = item?.booking_package_id;
        if (bookingPackageId !== null) {
          if (idOccurrences[bookingPackageId]) {
            idOccurrences[bookingPackageId]++;
          } else {
            idOccurrences[bookingPackageId] = 1;
          }
        }
      });

      const idOccurrencesArray = Object.entries(idOccurrences).map(([id, count]) => ({ id: parseInt(id), count }));

      const bookingProduct = map(itemsSort, (subitem, subindex) => {
        const sequent_number = subindex + 1;

        let product_type =
          subitem?.item_type === 'package' ? 'package' : subitem?.type ? Helper.convertItemType(subitem.type) : '-';
        let service_desc = '';

        if (subitem?.item_type === 'package') {
          idOccurrencesArray.forEach((item) => {
            if (subitem?.booking_package_id === item.id) {
              rowspan = item.count;
            }
          });
          service_desc = [
            { text: subitem?.name },
            { text: subitem?.voucher_label ? ` (${subitem?.voucher_label})` : '', italics: !!subitem?.voucher_label },
            { text: subitem?.note ? '\nKeterangan : ' + (subitem?.note ?? '') : '' },
            { text: `\n${subitem?.quantity} x Rp ${Helper.formatMoney(subitem?.total_base_price)}` },
            { text: `\n\n${Helper.convertItemType(subitem.type)}` },
            { text: `\n${subitem?.item_desc}` },
          ];
        } else {
          rowspan = 1;
          service_desc = [
            { text: subitem?.name ?? '-' },
            { text: subitem?.voucher_label ? ` (${subitem?.voucher_label})` : '', italics: !!subitem?.voucher_label },
            { text: subitem?.remark ? '\nKeterangan : ' + (subitem?.remark ?? '') : '' },
            {
              text: `\n${subitem?.quantity} x Rp ${Helper.formatMoney(+subitem?.base_price ?? 0)}`,
            },
          ];
        }
        return [
          { text: sequent_number, style: 'textCenter' },
          {
            text: `${subitem?.name ?? '-'}${subitem?.remark ? '\nKeterangan : ' + subitem?.remark ?? '' : ''}\n${
              subitem?.quantity ?? 0
            } x Rp ${Helper.formatMoney(+subitem?.base_price ?? 0)}`,
            text:
              product_type?.length > 1 ? product_type?.charAt(0)?.toUpperCase() + product_type?.slice(1) : product_type,
            rowSpan: rowspan,
            alignment: 'center',
          },
          {
            text: service_desc,
          },
          { text: `Rp ${Helper.formatMoney(+subitem?.discount)}`, style: 'textRight' },
          { text: `Rp ${Helper.formatMoney(+subitem?.voucher)}`, style: 'textRight' },
          { text: `Rp ${Helper.formatMoney(+subitem?.sub_total)}`, style: 'textRight' },
        ];
      });

      const bookingTotal = () => {
        let dataTotal = [
          [
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
          ],
          [
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: 'Total', alignment: 'right', colSpan: 2 },
            { text: '', border: [false] },
            { text: `Rp ${Helper?.formatMoney(+data?.grand_total ?? 0)}`, alignment: 'right' },
          ],
          [
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: 'DPP', alignment: 'right', colSpan: 2 },
            { text: '', border: [false] },
            { text: `Rp ${Helper?.formatMoney(+data?.dpp ?? 0)}`, alignment: 'right' },
          ],
          [
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: 'PPN', alignment: 'right', colSpan: 2 },
            { text: '', border: [false] },
            { text: `Rp ${Helper?.formatMoney(+data?.ppn ?? 0)}`, alignment: 'right' },
          ],
          [
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: 'PPH', alignment: 'right', colSpan: 2 },
            { text: '', border: [false] },
            { text: `Rp ${Helper?.formatMoney(+data?.pph ?? 0)}`, alignment: 'right' },
          ],
          [
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: '', border: [false] },
            { text: 'Payment Amount', alignment: 'right', colSpan: 2 },
            { text: '', border: [false] },
            { text: `Rp ${Helper?.formatMoney(+data?.amount ?? 0)}`, alignment: 'right' },
          ],
        ];

        return dataTotal;
      };

      return [
        {
          table: {
            widths: [25, 50, 175, 70, 70, 70],
            body: [['No. ', 'Type', 'Service Description', 'Discount', 'Voucher', 'Subtotal'], ...bookingProduct],
          },
          style: 'tableStyle',
          margin: [0, 16],
        },
        {
          pageBreak: 'before',
          table: {
            widths: [24, 50, '*', 70, 80, 85],
            body: [...bookingTotal()],
          },
          unbreakable: true,
          style: 'tableStyle',
          margin: [0, 0],
        },
      ];
    };

    const content = [
      {
        alignment: 'justify',
        columns: [
          {
            table: {
              body: [
                ['No', ':', dataBooking?.offering_letter_file_number],
                ['Lampiran', ':', '-'],
                ['Hal', ':', { text: ['Penawaran Harga ', { text: 'Service', italics: true }, ' Mobil'] }],
              ],
            },
            style: 'tableContent',
            fontSize: 12,
            layout: 'noBorders',
          },
          {
            text: ['Jakarta, ', dataBooking?.booking_created_at ?? '-'],
            alignment: 'right',
            style: 'tableContent',
          },
        ],
      },
      {
        text: 'Kepada Yth.',
        margin: [0, 20, 0, 0],
      },
      {
        text: dataBooking?.customer?.name ?? '-',
        margin: [0, 2, 0, 0],
      },
      {
        text: dataBooking?.customer?.address ?? '-',
        margin: [0, 2, 0, 0],
      },
      {
        text: 'Dengan Hormat,',
        margin: [0, 40, 0, 0],
      },
      {
        text: [
          'Bersamaan surat ini kami dari PT Oto Klix Indonesia bermaksud untuk menawarkan pemenuhan kebutuhan service mobil kepada ' +
            dataBooking?.customer?.name +
            ' di bengkel mitra kami. Rincian harga yang kami tawarkan untuk ',
          { text: carType, bold: true },
          ' adalah sebagai berikut:',
        ],
        margin: [0, 20, 0, 0],
      },
      {
        text: 'Service Detail',
        margin: [0, 30, 0, 0],
        bold: true,
        fontSize: 11,
      },
      ...bookingList(dataSummary),
      {
        text: 'Keterangan:',
        margin: [0, 10],
      },
      {
        type: 'dashed',
        ol: [
          'Biaya pengerjaan yang tercantum merupakan taksiran dan dapat berubah sesuai dengan hasil pengecekan di Bengkel.',
          'Jika pada Surat Perintah Kerja atau Work Order mencantumkan harga pada estimasi, maka Otoklix akan menunggu penerbitan SPK maksimal 2 hari dari tanggal estimasi diberikan.',
          'Jika dalam waktu 14 hari setelah SPK terbit dan belum ada kedatangan dari unit kendaraan maka Otoklix berhak menerbitkan invoice tanpa kedatangan customer.',
          'Apabila Perusahaan memutuskan untuk membatalkan pengerjaan setelah dilakukan pengecekan, maka biaya yang timbul dari proses pengecekan akan ditagihkan kepada Perusahaan.',
          'Pengerjaan yang diperintahkan oleh Perusahaan kepada Bengkel di luar estimasi dan tanpa sepengetahuan Otoklix menjadi tanggung jawab penuh Perusahaan dengan Bengkel.',
          'Harap tidak meninggalkan barang berharga di dalam mobil. Otoklix tidak bertanggung jawab atas segala kehilangan dan/atau kerusakan barang berharga yang terjadi saat pengerjaan.',
          'Syarat & ketentuan selengkapnya mengenai pembayaran servis diatur dalam invoice resmi.',
          'Dokumen ini bersifat rahasia dan tidak boleh disebarluaskan kepada pihak manapun.',
        ],
        margin: [15, 0, 0, 10],
      },
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: [
                  'Demikian surat penawaran ini kami sampaikan. Informasi lebih lanjut dapat menghubungi kami di ',
                  source == 'booking-detail'
                    ? dataBooking?.customer?.b2b_pic_phone ?? '-'
                    : dataBooking?.customer?.pic_phone ?? '-',
                  ' atau ',
                  source == 'booking-detail'
                    ? dataBooking?.customer?.b2b_pic_email ?? '-'
                    : dataBooking?.customer?.pic_email ?? '-',
                  '. Atas perhatian dan kerjasamanya kami ucapkan Terima kasih.',
                ],
                margin: [0, 10],
              },
            ],
            [
              {
                text: '\nHormat Kami \nPT Oto Klix Indonesia',
                margin: [0, 0],
              },
            ],
            [
              {
                text: 'Surat ini adalah cetakan komputer dan tidak memerlukan tandatangan.',
                margin: [0, 40],
                alignment: 'center',
                italics: true,
                fontSize: 10,
              },
            ],
          ],
        },
        unbreakable: true,
        layout: 'noBorders',
        margin: [0, 0],
      },
    ];

    if (source == 'booking-detail') {
      this.print(content, dataBooking?.booking_code);
    } else {
      return content;
    }
  }
}
