import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';

export default class BASTPdf {
  static print(content, bookingCode) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 180,
            style: {
              alignment: 'center',
              fontSize: 8,
            },
            margin: [0, 30, 0, 0],
          },
          {
            margin: [40, 0, 40, 0],
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 10,
                x2: 515,
                y2: 10,
                lineWidth: 1.7,
                lineColor: '#cc4200',
              },
            ],
          },
          {
            text: 'PT. OTO KLIX INDONESIA',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 10, 40, 0],
            bold: true,
          },
          {
            text: 'Jl. Terusan Sinabung No.1',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
          },
          {
            text: 'Grogol Selatan, Kebayoran Baru',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
          {
            text: 'Kota Jakarta Selatan, DKI Jakarta 12220. Indonesia',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 170, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          margin: [0, 0, 0, 0],
        },
        tableDash: {
          margin: [-50, 5, 0, -50],
        },
        tableSpace: {
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(`BAST ${bookingCode ?? ''}`);
  }

  static create(data) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    data.pic_otoklix = 'Business Development Team';

    const arrayData = [];
    const rowSpan = data?.summary?.detail_service?.length;

    data?.summary?.detail_service?.map((dt, index) => {
      if (index == 0) {
        arrayData.push([
          { rowSpan: rowSpan, text: 1, alignment: 'center', border: [false, true, false, true] },
          {
            rowSpan: rowSpan,
            text: data?.summary?.customer_car,
            alignment: 'center',
            border: [false, true, false, true],
          },
          { text: dt?.qty, alignment: 'center', border: [false, true, false, true] },
          {
            text: `${dt?.item}${dt?.remark ? '\nKeterangan : ' + dt?.remark ?? '' : ''}`,
            border: [false, true, false, true],
          },
        ]);
      } else if (index > 0) {
        arrayData.push([
          { text: '', border: [false, true, false, true] },
          { text: '', border: [false, true, false, true] },
          { text: dt?.qty, alignment: 'center', border: [false, true, false, true] },
          {
            text: `${dt?.item}${dt?.remark ? '\nKeterangan : ' + dt?.remark ?? '' : ''}`,
            border: [false, true, false, true],
          },
        ]);
      }
    });

    const content = [
      {
        text: 'BERITA ACARA SERAH TERIMA KENDARAAN',
        style: 'header',
        alignment: 'center',
        decoration: 'underline',
        bold: true,
        style: 'tableContent',
      },
      {
        text: [
          'Pada hari ini ',
          { text: data?.day ?? '-', bold: true },
          ' tanggal ',
          { text: data?.date ?? '-', bold: true },
          ' bulan ',
          { text: data?.month ?? '-', bold: true },
          ' tahun ',
          { text: data?.year ?? '-', bold: true },
          ' bertempat di ',
          { text: 'PT Oto Klix Indonesia', bold: true },
          ', yang bertanda tangan di bawah ini:',
        ],
        style: '',
        margin: [0, 20, 0, 20],
      },
      {
        table: {
          body: [
            ['Nama', ':', data?.pic_otoklix ?? '-'],
            ['Perusahaan', ':', { text: 'PT Oto Klix Indonesia', bold: true }],
            ['Alamat', ':', 'Jl. Radio Dalam Raya No. 22, Kebayoran Baru, Jakarta Selatan 12140'],
          ],
        },
        layout: 'noBorders',
      },
      {
        text: ['Selanjutnya disebut sebagai ', { text: 'Pihak Pertama', bold: true }, '.'],
        margin: [0, 20, 0, 20],
      },
      {
        table: {
          body: [
            ['Nama', ':', data?.pic_customer ?? '-'],
            ['Perusahaan', ':', { text: data?.customer_name ?? '-', bold: true }],
            ['Alamat', ':', data?.customer_address ?? '-'],
          ],
        },
        layout: 'noBorders',
      },
      {
        text: ['Selanjutnya disebut sebagai ', { text: 'Pihak Kedua', bold: true }, '.'],
        margin: [0, 20, 0, 20],
      },
      {
        text: [
          'Para Pihak dengan kedudukannya masing-masing seperti tersebut di atas, sepakat bahwa ',
          { text: 'Pihak Kedua', bold: true },
          ' telah menyerahkan 1 (satu) unit mobil kepada ',
          { text: 'Pihak Pertama', bold: true },
          ' sesuai dengan rincian sebagai berikut:',
        ],
        //  margin: [0, 20, 0, 20],
      },
      {
        table: {
          widths: [20, '*', 40, 150],
          body: [
            [
              { text: 'No', alignment: 'center', border: [false, true, false, true] },
              { text: 'Spesifikasi Mobil', alignment: 'center', border: [false, true, false, true] },
              { text: 'Qty', alignment: 'center', border: [false, true, false, true] },
              {
                text: ['Jenis ', { text: 'Service', italics: true }],
                alignment: 'center',
                border: [false, true, false, true],
              },
            ],
            ...arrayData,
          ],
        },
        margin: [0, 20, 0, 10],
      },
      {
        table: {
          widths: [350, '*'],
          body: [
            [
              {
                text:
                  'Demikian Berita Acara Serah Terima ini dibuat dengan sebenar-benarnya dan tanpa ada paksaan dari pihak manapun untuk dapat dipergunakan sebagaimana mestinya.',
                colSpan: 2,
                margin: [0, 20, 0, 40],
              },
              {},
            ],
            ['Pihak Pertama,', 'Pihak Kedua,'],
            [
              { text: '', margin: [0, 80, 0, 0] },
              { text: '', margin: [0, 80, 0, 0] },
            ],
            [data?.pic_otoklix ?? '__________________', data?.pic_customer ?? '__________________'],
          ],
        },
        unbreakable: true,
        layout: 'noBorders',
      },
    ];

    return content;
  }
}
