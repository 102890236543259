import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Label, Row, Input, FormFeedback, CardHeader } from 'reactstrap';
import InputField from '../field/InputField';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import SelectField from '../field/SelectField';
import API from '@utils/API';
import { isEmpty, map, findIndex } from 'lodash';
import Helper from '@helpers/Helper';
import PackageLineItems from './BookingPackageLineItems';
import WarningIcon from '@assets/icons/warning.svg';
import SelectAsyncField from '../field/SelectAsyncField';
import OverridePriceIcon from '@assets/icons/override-price.svg';
import SoldOut from '@assets/images/sold-out.png';
import Divider from '@components/divider/Divider';
import InfoIcon from '@assets/icons/info.svg';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { debounce } from 'lodash';
import newTabIcon from '@assets/icons/new-tab.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addTemporaryCart, getProducts } from '../../store/booking';

const BookingDetailItemForms = (props) => {
  const {
    categories,
    selectedWorkshop,
    selectedCar,
    selectedUser,
    accountType,
    handleChangeDetailOrder,
    disableAll,
    isECommerce,
    tab,
    handleResultNotification,
    markUpNDiscount,
    onClickModalCustom,
    allowBookingCustomForm,
    calculatePromo,
    promoPackageStatus,
    handleChangePromoPackageStatus,
    selectedWorkshops,
    handlePriceChange,
  } = props;
  const api = new API('v2');
  const apiSearch = new API('search');

  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [errorProduct, setErrorProduct] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [subCategoryProduct, setSubCategoryProduct] = useState();
  const [errorCategory, setErrorCategory] = useState('');
  const [remarks, setRemarks] = useState('');
  const [errorRemarks, setErrorRemarks] = useState('');
  const [qty, setQty] = useState(0);
  const [errorQty, setErrorQty] = useState('');
  const [sellPrice, setSellPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: 'IDR 0',
  });
  const [nettPrice, setNettPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: 'IDR 0',
  });
  const [errorSellPrice, setErrorSellPrice] = useState('');
  const [errorNettPrice, setErrorNettPrice] = useState('');
  const [hasOverrideSellPrice, setHasOverrideSellPrice] = useState(false);
  const [hasOverrideNettPrice, setHasOverrideNettPrice] = useState(false);

  const [isSplitedTax, setSplitedTax] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoData, setPromoData] = useState({});

  const handleOverride = () => {
    let canOverride = false;
    if (true) {
      if (!selectedProduct?.attr?.is_fbo) {
        if ((selectedProduct?.nett_price == 0 && selectedProduct?.attr?.is_wi) || !selectedProduct?.attr?.is_wi) {
          canOverride = true;
        }
      }
    }

    return canOverride;
  };

  const fetchProductList = async (selectedCategories, query = '', source = '') => {
    const workshopIds = selectedWorkshops.map((workshop) => workshop.id);

    let url = selectedWorkshop
      ? `v2/intools/products/items/workshop/${selectedWorkshop?.slug}`
      : 'v2/intools/products/items/master/';

    const params = {
      is_enterprise_customer: selectedUser?.isB2B === 'b2b' ? true : false,
      variant_car_id: selectedCar?.car_details?.id,
      category_id: selectedCategories,
      customer_id: selectedUser?.id,
      is_ecommerce: isECommerce,
      q: query,
      workshop_ids: workshopIds,
    };
    let data = [];

    if (source === 'category-filter') setLoadingProduct(true);

    if (selectedWorkshops.length > 1) {
      dispatch(getProducts(params))
        .then((res) => {
          data = res.payload.data;
          setProducts(data);
          if (source === 'category-filter') setLoadingProduct(false);
        })
        .catch((err) => {
          const errRes = err?.response?.data?.error?.message ?? '';
          setProducts(data);
          if (source === 'category-filter') setLoadingProduct(false);
          handleResultNotification({
            status: true,
            type: 'fail',
            message: `Gagal, ${errRes}`,
          });
        });
    } else {
      await apiSearch
        .get(url, { params })
        .then((res) => {
          data = res.data.data;
          setProducts(data);
          if (source === 'category-filter') setLoadingProduct(false);
        })
        .catch((err) => {
          const errRes = err?.response?.data?.error?.message ?? '';
          setProducts(data);
          if (source === 'category-filter') setLoadingProduct(false);
          handleResultNotification({
            status: true,
            type: 'fail',
            message: `Gagal, ${errRes}`,
          });
        });
    }

    return data ?? [];
  };

  const _loadSuggestions = (query, callback) => {
    let url = selectedWorkshop
      ? `v2/intools/products/items/workshop/${selectedWorkshop?.slug}`
      : 'v2/intools/products/items/master/';
    const params = {
      is_enterprise_customer: selectedUser?.isB2B === 'b2b' ? true : false,
      variant_car_id: selectedCar?.car_details?.id,
      category_id: selectedCategory?.value,
      customer_id: selectedUser?.id,
      is_ecommerce: isECommerce,
      q: query,
    };
    let data = [];

    return apiSearch
      .get(url, { params })
      .then((res) => {
        data = res.data.data;
        callback(res.data.data);
        setProducts(data);
      })
      .catch((err) => {
        const errRes = err?.response?.data?.error?.message ?? '';
        setProducts(data);
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal, ${errRes}`,
        });
      });
  };

  const getProductListWithCategories = (category) => {
    fetchProductList(category, '', 'category-filter');
  };

  const checkPromoCode = () => {
    if (selectedUser?.isB2B && !disableAll && promoData?.voucher_code != promoCode && promoCode) {
      const price = +selectedProduct?.price;
      const qty = +selectedProduct?.qty_modifier?.qty;
      const markup = selectedProduct?.type === 'part' ? markUpNDiscount?.markUpProduct : markUpNDiscount?.markUpService;
      const discount = selectedProduct?.type === 'part' ? markUpNDiscount?.discProduct : markUpNDiscount?.discService;
      const discountAmount = price * (+discount / 100);
      const totalPrice = qty * (price + price * (+markup / 100));

      const items = {
        promo_code: promoCode,
        product_id: selectedProduct?.product_id,
        qty: qty,
        price: price,
        markup_percentage: markup,
        discount_percentage: discount,
        discount_amount: discountAmount,
        total_price: totalPrice,
        source: 'add',
      };
      calculatePromo(items, 'item');
      getPromoDetail();
    }
  };

  const getPromoDetail = () => {
    if (selectedUser?.isB2B && !disableAll && promoData?.voucher_code != promoCode) {
      api
        .get(`v2/intools/campaigns/voucher-campaign/${promoCode}/`)
        .then((response) => {
          const data = response?.data?.data ?? {};
          const promo = {
            campaign_id: data?.campaign_id,
            campaign_name: data?.campaign_name,
            voucher_code: data?.voucher_code,
            status: 'success',
          };
          setPromoData(promo);
        })
        .catch((err) => {
          const errMessage = err?.response?.data?.error?.message ?? 'promo code may not be blank';
          const promo = {
            status: 'danger',
            message: errMessage,
          };
          setPromoData(promo);
        });
    }
  };

  const handleChangePromoCode = (value) => {
    setPromoCode(value?.toUpperCase());
    setPromoData({});
    handleChangePromoPackageStatus({});
  };

  const handleChangeCategories = (value) => {
    setSelectedCategory(value);
    setSubCategoryProduct();
    handleChangeRemarks('');
    if (value) {
      getProductListWithCategories(value?.value);
      setErrorCategory('');
      setPromoCode('');
      setPromoData({});
      handleChangePromoPackageStatus({});
      setSelectedProduct();
      handleChangeQty(0);
      handleChangeSellPrice(0);
      handleChangeNettPrice(0);
      setHasOverrideSellPrice(false);
      setHasOverrideNettPrice(false);
    }
  };

  const handleChangeProducts = (value) => {
    setSelectedProduct(value);
    setSubCategoryProduct();

    if (value) {
      const hasQtyModifier = value?.qty_modifier?.is_applied;

      if (errorProduct) setErrorProduct('');

      if (hasQtyModifier) {
        handleChangeQty(value.qty_modifier.qty);
      } else {
        handleChangeQty(1);
      }

      const categoriesIndex = findIndex(categories, (val) => {
        return val?.value === value?.product_category_id;
      });

      if (categoriesIndex !== -1) {
        setSelectedCategory(categories[categoriesIndex]);
      }

      setPromoCode('');
      setPromoData({});
      handleChangePromoPackageStatus({});
      setSubCategoryProduct(value?.product_subcategory_name);
      handleChangeSellPrice(value.price);
      handleChangeNettPrice(value.nett_price);
      setHasOverrideSellPrice(false);
      setHasOverrideNettPrice(false);
    }
  };

  const handleChangeRemarks = (value) => {
    setRemarks(value);

    if (value?.length > 100) {
      setErrorRemarks('Keterangan maksimal 100 karakter');
    } else {
      setErrorRemarks('');
    }
  };

  const handleChangeQty = (value) => {
    const qtyValue = value > 999 ? 999 : value;
    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatus({});

    try {
      const quantity = qtyValue;

      setQty(quantity);
    } catch {
      setQty(qtyValue);
    }

    if (value > 999) {
      setErrorQty('Maksimal jumlah yang dapat dipilih adalah 999');
    } else {
      setErrorQty('');
    }
  };

  const handleChangeOverrideSellPrice = (value) => {
    setHasOverrideSellPrice(value);
    if (!value) handleChangeSellPrice(selectedProduct?.price ?? 0);
  };

  const handleChangeOverrideMarkupSellPrice = (isOverride, price) => {
    if(accountType == 'b2b'){
      setHasOverrideSellPrice(isOverride);
      handleChangeSellPrice(price ?? 0);
    }
  };

  const handleChangeOverrideNettPrice = (value) => {
    setHasOverrideNettPrice(value);
    setSplitedTax(false);
    if (!value) handleChangeNettPrice(selectedProduct?.nett_price ?? 0);
  };

  const handleChangeSellPrice = (value) => {
    const sellPriceValue =
      Helper.removeMoneyFormat(value < 0 ? 0 : value) > 99999999999
        ? 99999999999
        : Helper.removeMoneyFormat(value < 0 ? 0 : value);

    try {
      const sellprice = Helper.formatMoneyOnField(sellPriceValue, 'IDR ');

      setSellPrice({ ...sellprice, isNoPriceAvailable: value < 0 ? true : false });
    } catch {
      setSellPrice({
        formatIsMoney: false,
        formatMoney: sellPriceValue,
        formatMoneyString: `IDR ${Helper.formatMoney(sellPriceValue)}`,
        isNoPriceAvailable: value < 0 ? true : false,
      });
    }

    if (Helper.removeMoneyFormat(value) > 99999999999) {
      setErrorSellPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    } else {
      setErrorSellPrice('');
    }
  };


  const handleChangeStdSellPriceByNettPrice = (value, isSplitTax = null) => {
    let valueType = typeof value;

    let nettPriceValue;
    if(valueType == "number"){
      nettPriceValue = value
    }else{
      nettPriceValue =
      Helper.removeMoneyFormat(value < 0 ? 0 : value) > 99999999999
        ? 99999999999
        : Helper.removeMoneyFormat(value < 0 ? 0 : value);
    
      handleChangeNettPrice(value);
    }
     
    if(isSplitTax == null && isSplitedTax){
      nettPriceValue = Helper.countDPP(nettPriceValue ?? 0)
    }

    let markupPrice = Helper.standardizeSellPriceCalculation(nettPriceValue?? 0);
    handleChangeOverrideMarkupSellPrice(true, markupPrice);
  }

  const handleChangeNettPrice = (value) => {
    const nettPriceValue =
      Helper.removeMoneyFormat(value < 0 ? 0 : value) > 99999999999
        ? 99999999999
        : Helper.removeMoneyFormat(value < 0 ? 0 : value);

    try {
      const nettprice = Helper.formatMoneyOnField(nettPriceValue, 'IDR ');
      setNettPrice({ ...nettprice, isNoPriceAvailable: value < 0 ? true : false });
    } catch {
      setNettPrice({
        formatIsMoney: false,
        formatMoney: nettPriceValue,
        formatMoneyString: `IDR ${Helper.formatMoney(nettPriceValue)}`,
        isNoPriceAvailable: value < 0 ? true : false,
      });
    }

    if (Helper.removeMoneyFormat(value) > 99999999999) {
      setErrorNettPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    } else {
      setErrorNettPrice('');
    }
  };

  const handleChangeSplitedTaxCheckBox = () => {
    setSplitedTax((prevState) => {
      const newState = !prevState;
      handleSplitTaxChangeNettPrice(newState);
      return newState; 
    });
  };
  
  const handleSplitTaxChangeNettPrice = (isSplitTax) => {
    let nettPriceValue;
    if(isSplitTax){
      nettPriceValue = Helper.countDPP(nettPrice?.formatMoney ?? 0)
    }else{
      nettPriceValue = nettPrice?.formatMoney ?? 0 
    }

    handleChangeStdSellPriceByNettPrice(nettPriceValue, isSplitTax)
  }

  const validate = () => {
    let status = true;
    if (!selectedProduct) {
      setErrorProduct('Item harus diisi!');
      status = false;
    } else {
      if (handleDetectProductType(selectedProduct?.type) === '') {
        setErrorProduct('Tipe Item tidak diketahui');
        status = false;
      }
    }

    if (remarks?.length > 100) {
      setErrorRemarks('Keterangan maksimal 100 karakter');
      status = false;
    }

    if (qty < 1) {
      setErrorQty('Minimal jumlah yang dapat dipilih adalah 1');
      status = false;
    } else if (qty > 999) {
      setErrorQty('Maksimal jumlah yang dapat dipilih adalah 999');
      status = false;
    }

    return status;
  };

  const handleDetectProductType = (productType) => {
    const isProduct = Helper.readIsProduct(productType ?? '');
    const isService = Helper.readIsService(productType ?? '');

    return isProduct ? 'product' : isService ? 'service' : '';
  };

  const handleDefaultLoadItems = () => {
    if (tab === 'item' && selectedUser) {
      fetchProductList('', '', 'search-filter');
    } else {
      setProducts([]);
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    const markup = selectedProduct?.type === 'part' ? markUpNDiscount?.markUpProduct : markUpNDiscount?.markUpService;
    const discount = selectedProduct?.type === 'part' ? markUpNDiscount?.discProduct : markUpNDiscount?.discService;

    // add unique id in selected product
    selectedProduct.uid = selectedProduct.uid || Helper.uniqueIdGenerator(12);

    const payload = {
      name: selectedProduct,
      categories: selectedCategory,
      remarks: remarks,
      qty: Number(qty),
      sellPrice: { ...sellPrice, value: sellPrice?.formatMoney },
      nettPrice: { ...nettPrice, value: nettPrice?.formatMoney },
      discountNominal: Helper.calculateDiscountNominal(
        markup,
        discount,
        sellPrice?.formatMoney ?? 0,
        Number(qty) ?? 0,
        markUpNDiscount
      ),
      isOverrideSell: hasOverrideSellPrice,
      isOverrideNett: hasOverrideNettPrice,
      type: handleDetectProductType(selectedProduct?.type),
      isSplitedTax: isSplitedTax,
      priceBeforeTax: Helper.countDPP(nettPrice?.formatMoney ?? 0),
      is_wi: selectedProduct?.attr?.is_wi,
      promoData: promoPackageStatus?.color == 'success' ? promoData : {},
      promoStatus: promoPackageStatus?.color == 'success' ? promoPackageStatus : {},
      workshopIds: selectedWorkshops.map((workshop) => workshop.id),
    };

    const originalPrice = !selectedProduct?.attr?.is_wi && selectedProduct?.price === 0 ? -1 : 0;
    const finalSellPrice =
      !selectedProduct?.attr?.is_wi && sellPrice?.formatMoney === 0 && !hasOverrideSellPrice
        ? -1
        : hasOverrideSellPrice
        ? sellPrice?.formatMoney
        : 0;

    const finalNettPrice = isSplitedTax ? Helper.countDPP(nettPrice?.formatMoney ?? 0, false) : nettPrice?.formatMoney;

    handlePriceChange(
      selectedWorkshop?.id,
      selectedProduct?.product_id,
      finalSellPrice,
      finalNettPrice,
      originalPrice,
      selectedProduct?.nett_price,
      isSplitedTax,
      nettPrice?.formatMoney,
      'allPrice',
      selectedProduct.uid
    );

    await handleChangeDetailOrder(payload);
    resetField();
  };

  const resetField = () => {
    handleChangeCategories();
    handleChangeProducts();
    handleChangeRemarks('');
    handleChangeQty(0);
    setHasOverrideSellPrice(false);
    setHasOverrideNettPrice(false);
    handleChangeSellPrice(0);
    handleChangeNettPrice(0);
    setSubCategoryProduct();
    handleDefaultLoadItems();
    setErrorCategory('');
    setErrorProduct('');
    setSplitedTax(false);
    setErrorRemarks('');
    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatus({});
  };

  useEffect(() => {
    resetField();
  }, [selectedWorkshop, tab]);

  useEffect(() => {
    handleDefaultLoadItems();
  }, [selectedCar, selectedUser]);

  useEffect(() => {
    if (selectedProduct) {
      const element = document.getElementById('item').getElementsByClassName('info')[0];
      element.setAttribute('data-tooltip-content', '');
    }
  }, [selectedProduct]);

  return (
    <Card className="booking-create-form-items-card">
      <CardBody>
        <Row>
          <Col>
            <Label className="cursor-pointer float-right mb-3" onClick={() => resetField()}>
              <u>Reset</u>
            </Label>
          </Col>
        </Row>
        <FormGroup row>
          <Label lg={3}>Kategori</Label>
          <SelectField
            id="item-category"
            colWidth={9}
            options={categories}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={handleChangeCategories}
            value={selectedCategory || ''}
            invalid={!selectedCategory}
            errorMessage={errorCategory}
            placeholder="Pilih Kategori..."
            disabled={disableAll || (!selectedCar && !isECommerce)}
          />
        </FormGroup>

        <FormGroup row>
          <Label lg={3}>Sub Kategori</Label>
          <InputField
            id="sub-category"
            colWidth={9}
            disabled={true}
            value={!subCategoryProduct ? '-' : subCategoryProduct}
          />
        </FormGroup>

        <FormGroup row>
          <Label lg={3} className="label-required">
            Item
          </Label>
          <Tooltip id={`info-similarity`} place="right-center" offset={30} position-strategy="fixed" />
          <SelectAsyncField
            id="item"
            colWidth={9}
            isDisabled={disableAll || loadingProduct}
            value={selectedProduct || ''}
            defaultOptions={products}
            getOptionLabel={(e) => (
              <span
                className="info"
                style={{ display: 'block', float: 'none' }}
                data-tooltip-id={`info-similarity`}
                data-tooltip-content={e?._sim?.content ?? ''}
              >
                {e?.attr?.is_fbo ? (
                  <span className="dropdown-tag">FBO</span>
                ) : e?.attr?.is_wi ? (
                  <span className="dropdown-tag__price-origin">WI</span>
                ) : (
                  <span className="dropdown-tag__price-origin no-wi">NO WI</span>
                )}
                {e.display_name}
                {/* {!e.is_compatible && <PackageLineItems items={e} />} */}
                {!e.is_compatible && <img id="warning-icon" src={WarningIcon} width={15} className="ml-1" />}
                {!e?.warning_state?.is_sellable && (
                  <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />
                )}
              </span>
            )}
            getOptionValue={(e) => e?.id ?? e?.product_id}
            loadOptions={debounce(_loadSuggestions, 600)}
            onChange={handleChangeProducts}
            invalid={!selectedProduct}
            errorMessage={errorProduct}
            placeholder="Pilih Item..."
            cacheOptions={false}
            loading={loadingProduct}
            isOptionDisabled={(e) => !e?.warning_state?.is_sellable}
            isCreate={allowBookingCustomForm ? true : false}
            textLabelCreate="Custom Item"
            onClickCreate={() => onClickModalCustom()}
          />
        </FormGroup>

        {selectedUser?.isB2B && (
          <FormGroup row>
            <Label lg={3}>Keterangan</Label>
            <InputField
              id="remarks"
              colWidth={9}
              onChange={(e) => handleChangeRemarks(e.target.value)}
              disabled={!selectedProduct || disableAll}
              value={remarks}
              placeholder={'Cth: Keterangan Tahun, Tukar Tambah, dll'}
              invalid={!!errorRemarks}
              errorMessage={errorRemarks}
            />
          </FormGroup>
        )}

        <FormGroup row>
          <Label lg={3}>Jumlah</Label>
          <InputField
            inputType="number"
            id="item-qty"
            colWidth={9}
            onChange={(e) => handleChangeQty(e.target.value)}
            value={qty.toString()}
            disabled={!selectedProduct || disableAll}
            invalid={!!errorQty}
            errorMessage={errorQty}
          />
        </FormGroup>

        {selectedWorkshop && selectedWorkshops.length <= 1 && (
          <FormGroup row>
            <Label lg={3}>Harga Jual Customer</Label>
            <Col lg={7} sm={11}>
              <Input
                id="sell-price"
                className="input-field"
                disabled={!hasOverrideSellPrice || disableAll}
                onChange={(e) => {
                  if (hasOverrideSellPrice && !disableAll) {
                    handleChangeSellPrice(e.target.value);
                  }
                }}
                value={sellPrice?.isNoPriceAvailable ? 'IDR -' : sellPrice.formatMoneyString}
                invalid={!!errorSellPrice}
              />
              <FormFeedback>{errorSellPrice ?? ''}</FormFeedback>
            </Col>

            {true && (
              <Col lg={2} sm={1} className="d-flex justify-content-end">
                <Button
                  id="override-sell-price"
                  color="primary"
                  size="lg"
                  className={`justify-self-right ${
                    hasOverrideSellPrice ? 'btn-override-price' : 'btn-no-override-price'
                  }`}
                  disabled={disableAll || !selectedProduct}
                  onClick={() => handleChangeOverrideSellPrice(!hasOverrideSellPrice)}
                >
                  <img src={OverridePriceIcon} height={15} className="override-icon" />
                </Button>
              </Col>
            )}
          </FormGroup>
        )}

        {selectedWorkshop?.is_pkp && selectedWorkshops.length <= 1 && hasOverrideNettPrice && (
          <>
            <Divider />
            <FormGroup row>
              <Tooltip id="info-split-tax" />
              <Label lg={3}>
                <img
                  src={InfoIcon}
                  alt=""
                  className="mr-1 cursor-pointer"
                  width={16}
                  data-tooltip-id="info-split-tax"
                  data-tooltip-content="Jika diaktifkan, maka operator mengisi Harga Jual Bengkel termasuk PPN lalu sistem akan menghitung DPP"
                />
                Split Tax Pricing
              </Label>
              <Col lg={9} style={{ padding: '6px 12px', display: 'flex', alignItems: 'center' }}>
                <Input
                  id="split-tax-check"
                  type="checkbox"
                  onChange={handleChangeSplitedTaxCheckBox}
                  checked={isSplitedTax}
                  disabled={disableAll || !!!selectedWorkshop?.is_pkp}
                />
              </Col>
            </FormGroup>

            {isSplitedTax && (
              <FormGroup row>
                <Label lg={3}>Harga Jual Bengkel</Label>
                <Col lg={9} sm={12} className="d-flex align-items-center">
                  <span>{`IDR ${Helper.formatMoney(Helper.countDPP(nettPrice?.formatMoney))}`}</span>
                </Col>
              </FormGroup>
            )}
          </>
        )}

        {!isEmpty(selectedWorkshop) && selectedWorkshops.length <= 1 && !nettPrice.isNoPriceAvailable ? (
          <FormGroup row>
            <Label lg={3}>{isSplitedTax ? 'Harga Jual Bengkel Termasuk PPN' : 'Harga Jual Bengkel'}</Label>
            <Col lg={!handleOverride() ? 9 : 7} sm={!handleOverride() ? 12 : 11}>
              <Input
                id="nett-price"
                className="input-field"
                disabled={!hasOverrideNettPrice || disableAll}
                onChange={(e) => {
                  if (hasOverrideNettPrice && !disableAll) {
                    handleChangeStdSellPriceByNettPrice(e.target.value);
                  }
                }}
                value={nettPrice?.isNoPriceAvailable ? 'IDR -' : nettPrice.formatMoneyString}
                invalid={!!errorNettPrice}
              />
              <FormFeedback>{errorNettPrice ?? ''}</FormFeedback>
            </Col>
            {handleOverride() && (
              <Col lg={2} sm={1} className="d-flex justify-content-end">
                <Button
                  id="override-nett-price"
                  color="primary"
                  size="lg"
                  className={`justify-self-right ${
                    hasOverrideNettPrice ? 'btn-override-price' : 'btn-no-override-price'
                  }`}
                  disabled={disableAll || !selectedProduct}
                  onClick={() => handleChangeOverrideNettPrice(!hasOverrideNettPrice)}
                >
                  <img src={OverridePriceIcon} height={15} className="override-icon" />
                </Button>
              </Col>
            )}
          </FormGroup>
        ) : null}

        {selectedUser?.isB2B && (
          <FormGroup row>
            <Label lg={3}>Kode Promo</Label>
            <InputField
              colWidth={6}
              placeholder="promo code"
              value={promoCode}
              onChange={(e) => handleChangePromoCode(e?.target?.value)}
              disabled={disableAll || !selectedProduct}
              valid={!!promoPackageStatus && promoPackageStatus?.color == 'success'}
              invalid={!!promoPackageStatus && promoPackageStatus?.color == 'danger'}
              errorMessage={promoPackageStatus?.color == 'danger' ? promoPackageStatus?.message : ''}
            />
            <Col lg={3}>
              <Button
                id="create-booking"
                color="purple"
                onClick={checkPromoCode}
                disabled={disableAll || !selectedProduct}
              >
                Pakai
              </Button>
            </Col>
            <Col lg={12} className="mt-2">
              {promoData?.campaign_id && (
                <span>
                  Detail campaign <span className="promo-name">{promoData?.campaign_name ?? '-'}</span> dapat diakses
                  disini{' '}
                  <span>
                    <Link to={`/campaigns/campaign/detail/${promoData?.campaign_id ?? ''}`} target="_blank">
                      <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                    </Link>
                  </span>
                </span>
              )}
            </Col>
          </FormGroup>
        )}

        <Row className="justify-content-center">
          <Button
            id="item-add"
            color="primary"
            size="lg"
            className="btn-add-item"
            disabled={disableAll}
            style={{ marginBottom: '10px' }}
            onClick={handleSubmit}
          >
            <img src={PlusFill} className="mr-2" />
            Tambah
          </Button>
        </Row>
      </CardBody>
    </Card>
  );
};

export default BookingDetailItemForms;