import Helper from '@helpers/Helper';
import { map } from 'lodash';

export default class EstimationPriceText {
  static create(dataBooking, dataSummary) {
    const splitTime = dataBooking?.booking_time ? dataBooking?.booking_time.split('.') : [];
    const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    let bookingSchedule = dataBooking?.booking_date ?? '';
    bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = dataBooking?.user_car?.car_details?.car_model?.brand?.name
      ? dataBooking?.user_car?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.car_model?.model_name
      ? dataBooking?.user_car?.car_details?.car_model?.model_name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.variant ? dataBooking?.user_car?.car_details?.variant : '';
    const contenHeader = this.headerContent(dataBooking);
    const contentBody = this.bodyContent(dataSummary);
    const contentFooter = this.footerContent();

    return contenHeader + contentBody + contentFooter;
  }

  static headerContent(dataBooking) {
    const splitTime = dataBooking?.booking_time ? dataBooking?.booking_time.split('.') : [];
    const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    let bookingSchedule = dataBooking?.booking_date ?? '';
    bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = dataBooking?.user_car?.car_details?.car_model?.brand?.name
      ? dataBooking?.user_car?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.car_model?.model_name
      ? dataBooking?.user_car?.car_details?.car_model?.model_name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.variant ? dataBooking?.user_car?.car_details?.variant : '';

    let headerText = '';
    headerText += `*Estimasi Biaya Pengerjaan*\n\n`;
    headerText += `*Kode Booking* : ${dataBooking?.booking_code ?? '-'}`;
    headerText += `\n*Jadwal Booking* : ${!bookingSchedule ? '-' : bookingSchedule}`;
    headerText += `\n*Pilihan Lokasi* : ${dataBooking?.workshop?.name ?? '-'}`;
    headerText += `\n*Gmaps Link* : ${dataBooking?.workshop?.gmaps_link ?? '-'}`;
    headerText += `\n*Nama Customer* : ${dataBooking?.customer?.name ?? '-'}`;
    headerText += `\n*Jenis Mobil* : ${!carType ? '-' : carType}`;
    headerText += `\n*Nomor Plat* : ${dataBooking?.user_car?.license_plate ?? '-'}`;

    return headerText;
  }

  static bodyContent(dataSummary) {
    let bodyText = `\n`;
    const packages = dataSummary?.items?.packages;
    const products = dataSummary?.items?.products;
    const services = dataSummary?.items?.services;
    if (packages?.package_items?.length > 0) {
      bodyText += '\n\n*Paket*';
      map(packages?.package_items, (item) => {
        bodyText += `\n\n${item?.name ?? '-'} Rp ${Helper.formatMoney(item?.price ?? 0)}`;
        const voucherAmount = item?.subtotal_voucher > 0 ? `Rp ${Helper.formatMoney(item?.subtotal_voucher)}` : '';
        const voucherData = `(${item?.item_details[0]?.voucher_code} ${
          item?.item_details[0]?.voucher_percentage
            ? `${item?.item_details[0]?.voucher_percentage}% ${voucherAmount}`
            : `Rp ${Helper.formatMoney(item?.item_details[0]?.voucher_amount)}`
        })`;
        if (item?.item_details[0]?.voucher_code) {
          bodyText += `\n${voucherData}`;
        }
        map(item?.item_details, (subitem) => {
          bodyText += `\n${subitem?.qty ?? '-'} x ${subitem?.name ?? '-'}`;
        });
        bodyText += `${item?.item_details[0]?.note ? '\nKeterangan : ' + item?.item_details[0]?.note ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Paket Rp ${Helper.formatMoney(packages?.package_items[0]?.subtotal)}*`;
    }

    if (products?.product_items?.length > 0) {
      bodyText += '\n\n*Produk*';
      map(products?.product_items, (item) => {
        bodyText += `\n\n${item?.name ?? '-'} Rp ${Helper.formatMoney(item?.price ?? 0)}`;
        bodyText += `\n${item?.qty ?? '-'} x Rp ${Helper.formatMoney(
          item?.marked_up_unit_price ?? 0
        )} - Rp ${Helper.formatMoney(item?.discount_amount ?? 0)}`;
        const voucherAmount = item?.voucher_amount > 0 ? `Rp ${Helper.formatMoney(item?.voucher_amount)}` : '';
        const voucherData = `(${item?.voucher_code} ${
          item?.voucher_percentage
            ? `${item?.voucher_percentage}% ${voucherAmount}`
            : `Rp ${Helper.formatMoney(item?.voucher_amount)}`
        })`;
        if (item?.voucher_code) {
          bodyText += `\n${voucherData}`;
        }
        bodyText += `${item?.remark ? '\nKeterangan : ' + item?.remark ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Produk Rp ${Helper.formatMoney(products?.subtotal_product)}*`;
    }

    if (services?.service_items?.length > 0) {
      bodyText += '\n\n*Jasa*';
      map(services?.service_items, (item) => {
        bodyText += `\n\n${item?.name ?? '-'} Rp ${Helper.formatMoney(item?.price ?? 0)}`;
        bodyText += `\n${item?.qty ?? '-'} x Rp ${Helper.formatMoney(
          item?.marked_up_unit_price ?? 0
        )} - Rp ${Helper.formatMoney(item?.discount_amount ?? 0)}`;
        const voucherAmount = item?.voucher_amount > 0 ? `Rp ${Helper.formatMoney(item?.voucher_amount)}` : '';
        const voucherData = `(${item?.voucher_code} ${
          item?.voucher_percentage
            ? `${item?.voucher_percentage}% ${voucherAmount}`
            : `Rp ${Helper.formatMoney(item?.voucher_amount)}`
        })`;
        if (item?.voucher_code) {
          bodyText += `\n${voucherData}`;
        }
        bodyText += `${item?.remark ? '\nKeterangan : ' + item?.remark ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Jasa Rp ${Helper.formatMoney(services?.subtotal_service)}*`;
    }

    bodyText += `\n\n*DPP Rp ${Helper.formatMoney(dataSummary?.subtotal ?? 0)}*`;
    bodyText += `\n*Total Rp ${Helper.formatMoney(dataSummary?.grand_total ?? 0)}*`;
    bodyText += `\n*Total Diskon Rp ${Helper.formatMoney(dataSummary?.discount ?? 0)}*`;
    bodyText += `\n*PPN Rp ${Helper.formatMoney(dataSummary?.ppn ?? 0)}*`;
    bodyText += `\n*PPH Rp ${Helper.formatMoney(dataSummary?.pph ?? 0)}*`;
    bodyText += `\n----------------------------`;
    bodyText += `\n*Total Bayar Rp ${Helper.formatMoney(dataSummary?.amount ?? 0)}*`;
    bodyText += `\n----------------------------`;

    return bodyText;
  }

  static footerContent() {
    let footerText = '';
    footerText += `\n\n\n*Syarat & Ketentuan:*`;
    footerText += `\n1. Biaya pengerjaan yang tercantum merupakan taksiran dan dapat berubah sesuai dengan hasil pengecekan di Bengkel.`;
    footerText += `\n2. Jika pada Surat Perintah Kerja atau Work Order mencantumkan harga pada estimasi, maka Otoklix akan menunggu penerbitan SPK maksimal 2 hari dari tanggal estimasi diberikan.`;
    footerText += `\n3. Jika dalam waktu 14 hari setelah SPK terbit dan belum ada kedatangan dari unit kendaraan maka Otoklix berhak menerbitkan invoice tanpa kedatangan customer.`;
    footerText += `\n4. Apabila Perusahaan memutuskan untuk membatalkan pengerjaan setelah dilakukan pengecekan, maka biaya yang timbul dari proses pengecekan akan ditagihkan kepada Perusahaan.`;
    footerText += `\n5. Pengerjaan yang diperintahkan oleh Perusahaan kepada Bengkel di luar estimasi dan tanpa sepengetahuan Otoklix menjadi tanggung jawab penuh Perusahaan dengan Bengkel.`;
    footerText += `\n6. Harap tidak meninggalkan barang berharga di dalam mobil. Otoklix tidak bertanggung jawab atas segala kehilangan dan/atau kerusakan barang berharga yang terjadi saat pengerjaan.`;
    footerText += `\n7. Syarat & ketentuan selengkapnya mengenai pembayaran servis diatur dalam invoice resmi.`;
    footerText += `\n8. Dokumen ini bersifat rahasia dan tidak boleh disebarluaskan kepada pihak manapun.`;

    return footerText;
  }
}
