import React, { useState, useEffect } from 'react';
import { map, find, findIndex } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { validate } from 'react-email-validator';
import { Row, Col, Label, Card, CardHeader, CardBody, Input, FormGroup } from 'reactstrap';
import API from '@utils/API';
import PlusIcon from '@assets/icons/plus.svg';
import MinusIcon from '@assets/icons/minus.svg';
import TrashIcon from '@assets/icons/trash.svg';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';

const CreateAdvance = (props) => {
  const { isSave, disableForm, onResultValidate } = props;
  const api = new API('v2');
  const [formData, setFormData] = useState();
  const [showAdvance, setShowAdvance] = useState(false);
  const [tierOptions, setTierOptions] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [capabilitiesOptions, setCapabilitiesOptions] = useState([]);
  const [loadingFetchSpeciality, setLoadingFetchSpeciality] = useState(false);
  const [loadingFetchTierOptions, setLoadingFetchTierOptions] = useState(false);

  const optionsMOU = [
    {
      value: 'not_approved',
      label: 'Tidak disetujui',
    },
    {
      value: 'on_process',
      label: 'Sedang diajukan',
    },
    {
      value: 'approved',
      label: 'Disetujui',
    },
  ];

  const optionsCompliance = [ // TODO: change to api call
    {
      value: 'normal',
      label: 'Normal',
    },
    {
      value: 'under_review',
      label: 'Under Review',
    },
    {
      value: 'terminated',
      label: 'Terminated',
    },
    {
      value: 'restricted',
      label: 'Restricted',
    },
  ];

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: disableForm,
    onDrop: (files) => {
      handleDropFile(files[0]);
      setIsDragOver(false);
    },
    onDragOver: () => {
      removeErrorDocument();
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    onFileDialogOpen: () => {
      removeErrorDocument();
    },
  });

  const handleDefaultData = () => {
    const defaultForm = {
      pic_name: '',
      pic_phone: '',
      workshop_email: '',
      owner_email: '',
      wa_group: '',
      is_service_warranty: false,
      is_fbo: false,
      is_franchise: false,
      document_name: '',
      document_url: '',
      document_file: '',
      facilities: [],
      capabilities: [],
      speciality: null,
      tier: {
        value: 'non_verified',
        label: 'Non-Verified Workshop',
      },
      compliance: {
        value: 'under_review',
        label: 'Under Review',
      },
      status: 'deactivate',
      status_mou: {
        value: 'not_approved',
        label: 'Tidak disetujui',
      },
      pic_otoklix: '',
      description: '',
      error_pic_name: '',
      error_pic_phone: '',
      error_workshop_email: '',
      error_owner_email: '',
      error_wa_group: '',
      error_description: '',
      error_document: '',
      error_pic_otoklix: '',
    };

    setFormData(defaultForm);
  };

  const removeErrorDocument = () => {
    if (formData.error_document !== '') {
      formData.error_document = '';
      setFormData({ ...formData });
    }
  };

  const handleDropFile = (data) => {
    const file = data;
    const maxSize = 10240;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['jpeg', 'jpg', 'png', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      formData.error_document = 'Format file tidak diketahui';
      setFormData({ ...formData });
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        formData.error_document = 'File tidak sesuai dengan format';
        setFormData({ ...formData });
      } else {
        if (fileSize == null) {
          formData.error_document = 'Ukuran file tidak diketahui';
          setFormData({ ...formData });
        } else if (fileSize > maxSize) {
          formData.error_document = 'Ukuran file maksimal 10mb';
          setFormData({ ...formData });
        } else {
          const fileUrl = URL.createObjectURL(file);
          formData.error_document = '';
          formData.document_name = file?.name ?? '';
          formData.document_url = fileUrl ?? '';
          formData.document_file = file ?? '';
          setFormData({ ...formData });
        }
      }
    }
  };

  const handleDeleteDocument = () => {
    if (!disableForm) {
      formData.document_name = '';
      formData.document_url = '';
      formData.document_file = '';
      setFormData({ ...formData });
    }
  };

  const handleDownloadDocument = () => {
    if (!disableForm) {
      const link = document.createElement('a');
      link.setAttribute('href', formData.document_url);
      link.setAttribute('download', formData.document_name ?? '');
      link.click();
    }
  };

  async function fetchSpeciality() {
    setLoadingFetchSpeciality(true);
    try {
      const response = await api.get(`v2/intools/workshops/specialities/`);
      const optionsSpeciality = map(response?.data?.data, (item) => {
        return { value: item?.id ?? null, label: item?.name ?? '-' };
      });
      setSpecialityOptions(optionsSpeciality);
      setLoadingFetchSpeciality(false);
    } catch (error) {
      setSpecialityOptions([]);
      setLoadingFetchSpeciality(false);
    }
  }

  async function fetchFacilities() {
    try {
      const response = await api.get(`v2/intools/workshops/facilities/`);
      const optionsFacilities = map(response?.data?.data, (item) => {
        return { value: item?.id ?? null, label: item?.name ?? '-', is_available: false };
      });
      setFacilityOptions(optionsFacilities);
    } catch (error) {
      setFacilityOptions([]);
    }
  }

  async function fetchCapabilities() {
    try {
      const response = await api.get(`v2/intools/workshops/capabilities/`);
      const optionsCapabilities = map(response?.data?.data, (item) => {
        return { value: item?.id ?? null, label: item?.name ?? '-', is_available: false };
      });
      setCapabilitiesOptions(optionsCapabilities);
    } catch (error) {
      setCapabilitiesOptions([]);
    }
  }

  async function fetchTierOptions() {
    setLoadingFetchTierOptions(true);
    try {
      const response = await api.get('v2/intools/workshops/tier_options/');
      const optionsTier = map(response?.data?.data, (item) => {
        return { value: item?.value ?? null, label: item?.name ?? '-' };
      });
      setTierOptions(optionsTier);
      setLoadingFetchTierOptions(false);
    } catch (error) {
      setTierOptions([]);
      setLoadingFetchTierOptions(false);
    }
  }

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangePICName = (e) => {
    validatePICName(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.pic_name);
  };

  const handleChangePICOtoklix = (e) => {
    validatePICOtoklix(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.pic_otoklix);
  };

  const handleChangePICPhone = (e) => {
    let value = e?.target?.value.replace(/[^0-9]/g, '');
    value.replace(/(?!^\+)\+/g, '');
    validatePICPhone(value);
  };

  const handleChangeWorkshopEmail = (e) => {
    validateWorkshopEmail(e?.target?.value !== '' ? e?.target?.value.replace(/ +/g, '') : e?.target?.value);
  };

  const handleChangeOwnerEmail = (e) => {
    validateOwnerEmail(e?.target?.value !== '' ? e?.target?.value.replace(/ +/g, '') : e?.target?.value);
  };

  const handleChangeWaGroup = (e) => {
    validateWaGroup(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.wa_group);
  };

  const handleChangeServiceWarranty = (e) => {
    setChangeValueForm('is_service_warranty', e?.target?.checked ? true : false);
  };

  const handleChangeFBO = (e) => {
    setChangeValueForm('is_fbo', e?.target?.checked ? true : false);
  };

  const handleChangeFranschise = (e) => {
    setChangeValueForm('is_franchise', e?.target?.checked ? true : false);
  };

  const handleChangeStatus = (e) => {
    setChangeValueForm('status', e);
  };

  const handleChangeTier = (e) => {
    setChangeValueForm('tier', e);
  };

  const handleChangeMoU = (e) => {
    setChangeValueForm('status_mou', e);
  };

  const handleChangeSpeciality = (e) => {
    setChangeValueForm('speciality', e);
  };

  const handleChangeFacilities = (item) => {
    let facilities = formData.facilities;
    const facilitiesIndex = findIndex(facilities, (value) => {
      return value?.value === item.value;
    });

    if (facilitiesIndex !== -1) {
      formData.facilities[facilitiesIndex].is_available = !item.is_available;
      setChangeValueForm('facilities', formData.facilities);
    }
  };

  const handleChangeCapabilities = (item) => {
    let capabilities = formData.capabilities;
    const capabilitiesIndex = findIndex(capabilities, (value) => {
      return value?.value === item.value;
    });

    if (capabilitiesIndex !== -1) {
      formData.capabilities[capabilitiesIndex].is_available = !item.is_available;
      setChangeValueForm('capabilities', formData.capabilities);
    }
  };

  const handleChangeDescription = (e) => {
    validateDescription(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.description);
  };

  const validatePICName = (value) => {
    const errorMsg = value?.length > 50 ? 'Format Nama PIC Bengkel maksimal 50 karakter' : '';
    setChangeValueForm('pic_name', value, 'error_pic_name', errorMsg);
    return errorMsg ? false : true;
  };

  const validatePICOtoklix = (value) => {
    const errorMsg = value?.length > 50 ? 'Format Nama PIC Otoklix maksimal 50 karakter' : '';
    setChangeValueForm('pic_otoklix', value, 'error_pic_otoklix', errorMsg);
    return errorMsg ? false : true;
  };

  const validatePICPhone = (value) => {
    const errorMsg = value?.length > 17 ? 'Format Nomor Telp PIC maksimal 17 karakter' : '';
    setChangeValueForm('pic_phone', value, 'error_pic_phone', errorMsg);
    return errorMsg ? false : true;
  };

  const validateWorkshopEmail = (value) => {
    const emailCheck = validate(value);
    const errorMsg = !emailCheck && value ? 'Format Email Bengkel tidak sesuai' : '';
    setChangeValueForm('workshop_email', value, 'error_workshop_email', errorMsg);
    return errorMsg ? false : true;
  };

  const validateOwnerEmail = (value) => {
    const emailCheck = validate(value);
    const errorMsg = !emailCheck && value ? 'Format Email Pemilik Bengkel tidak sesuai' : '';
    setChangeValueForm('owner_email', value, 'error_owner_email', errorMsg);
    return errorMsg ? false : true;
  };

  const validateWaGroup = (value) => {
    const errorMsg = value?.length > 100 ? 'Format WA Group maksimal 100 karakter' : '';
    setChangeValueForm('wa_group', value, 'error_wa_group', errorMsg);
    return errorMsg ? false : true;
  };

  const validateDescription = (value) => {
    const errorMsg = value?.length > 2000 ? 'Format Description maksimal 2000 karakter' : '';
    setChangeValueForm('description', value, 'error_description', errorMsg);
    return errorMsg ? false : true;
  };

  const handleShowHideAdvance = () => {
    if (!showAdvance) {
      setTimeout(() => {
        window.scrollTo({
          top: 100000,
          behavior: 'smooth',
        });
      }, 100);
    }
    setShowAdvance(!showAdvance);
  };

  const validateFormAdvance = () => {
    const isValidPICName = validatePICName(formData?.pic_name ?? '');
    const isValidPICPhone = validatePICPhone(formData?.pic_phone ?? '');
    const isValidWorkshopEmail = validateWorkshopEmail(formData?.workshop_email ?? '');
    const isValidOwnerEmail = validateOwnerEmail(formData?.owner_email ?? '');
    const isValidWaGroup = validateWaGroup(formData?.wa_group ?? '');
    const isValidDescription = validateDescription(formData?.description ?? '');
    const isValidPICOtoklix = validatePICOtoklix(formData?.pic_otoklix ?? '');

    if (
      isValidPICName &&
      isValidPICPhone &&
      isValidWorkshopEmail &&
      isValidOwnerEmail &&
      isValidWaGroup &&
      isValidDescription &&
      isValidPICOtoklix
    ) {
      handleValidate(true);
    } else {
      handleValidate(false);
      setShowAdvance(true);
    }
  };

  const handleValidate = (pass) => {
    onResultValidate({
      is_pass: pass,
      data_form: formData,
    });
  };

  useEffect(() => {
    if (formData?.facilities) {
      formData.facilities = facilityOptions;
      setFormData({ ...formData });
    }
  }, [facilityOptions]);

  useEffect(() => {
    if (formData?.capabilities) {
      formData.capabilities = capabilitiesOptions;
      setFormData({ ...formData });
    }
  }, [capabilitiesOptions]);

  useEffect(() => {
    if (isSave) {
      validateFormAdvance();
    }
  }, [isSave]);

  useEffect(() => {
    fetchSpeciality();
    fetchFacilities();
    fetchCapabilities();
    fetchTierOptions();
    handleDefaultData();
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="text-white mt-0 cursor-pointer" onClick={handleShowHideAdvance}>
          Lanjutan
          <div className="advance-area-icon">
            <img src={showAdvance ? MinusIcon : PlusIcon} alt="" />
          </div>
        </CardHeader>
        {showAdvance && (
          <CardBody className="card-custom">
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Nama PIC Bengkel
                  </Label>
                  <InputField
                    colWidth={8}
                    name="pic_name"
                    inputType={'text'}
                    disabled={disableForm}
                    value={formData?.pic_name}
                    invalid={formData?.error_pic_name ? true : false}
                    errorMessage={formData?.error_pic_name ?? ''}
                    placeholder={'Nama PIC Bengkel'}
                    onChange={handleChangePICName}
                  ></InputField>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Nomor Telp PIC Bengkel
                  </Label>
                  <InputField
                    colWidth={8}
                    name="pic_phone"
                    inputType={'text'}
                    disabled={disableForm}
                    value={formData?.pic_phone}
                    invalid={formData?.error_pic_phone ? true : false}
                    errorMessage={formData?.error_pic_phone ?? ''}
                    placeholder={'Nomor Telp PIC Bengkel'}
                    onChange={handleChangePICPhone}
                  ></InputField>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Email Bengkel
                  </Label>
                  <InputField
                    colWidth={8}
                    name="workshop_email"
                    inputType={'text'}
                    disabled={disableForm}
                    value={formData?.workshop_email}
                    invalid={formData?.error_workshop_email ? true : false}
                    errorMessage={formData?.error_workshop_email ?? ''}
                    placeholder={'Email Bengkel'}
                    onChange={handleChangeWorkshopEmail}
                  ></InputField>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Email Pemilik Bengkel
                  </Label>
                  <InputField
                    colWidth={8}
                    name="owner_email"
                    inputType={'text'}
                    disabled={disableForm}
                    value={formData?.owner_email}
                    invalid={formData?.error_owner_email ? true : false}
                    errorMessage={formData?.error_owner_email ?? ''}
                    placeholder={'Email Pemilik Bengkel'}
                    onChange={handleChangeOwnerEmail}
                  ></InputField>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    WA Group Link
                  </Label>
                  <InputField
                    colWidth={8}
                    name="wa_group"
                    inputType={'text'}
                    disabled={disableForm}
                    value={formData?.wa_group}
                    invalid={formData?.error_wa_group ? true : false}
                    errorMessage={formData?.error_wa_group ?? ''}
                    placeholder={'WA Group Link'}
                    onChange={handleChangeWaGroup}
                  ></InputField>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Speciality
                  </Label>
                  <SelectField
                    colWidth={8}
                    name="speciality"
                    placeholder="Pilih Speciality"
                    closeMenuOnSelect={true}
                    value={formData?.speciality}
                    options={specialityOptions}
                    disabled={disableForm}
                    isLoading={loadingFetchSpeciality}
                    onChange={handleChangeSpeciality}
                  />
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Dokumen
                  </Label>
                  <Col lg={8} className="text-left text-bold">
                    {formData?.document_name && (
                      <div className="document-box-workshop">
                        <span className="file" onClick={handleDownloadDocument}>
                          {formData?.document_name}
                        </span>
                        <img className="icon-trash-workshop" src={TrashIcon} onClick={handleDeleteDocument} />
                      </div>
                    )}
                    {!formData?.document_name && (
                      <div>
                        {isDragOver && (
                          <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                            <span className="choose-file">Release File</span>
                          </div>
                        )}
                        {!isDragOver && (
                          <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                            <span className="choose-file">Drag and Drop or Choose File</span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="invalid-document">{formData?.error_document}</div>
                    <div className="document-guide">Max upload 10mb</div>
                    <div className="document-guide">jpeg, jpg, png, xls, xlsx, doc, docx, ppt, pptx</div>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row>
                  <Label lg={4} className="text-left">
                    Service Warranty
                  </Label>
                  <Col lg={8} className="p-2">
                    <ActionSwitch
                      customClass="mb-4"
                      color="secondary"
                      inputName={`is_service_warranty`}
                      isChecked={formData?.is_service_warranty ? true : false}
                      editableForm={disableForm}
                      onChange={handleChangeServiceWarranty}
                    />
                  </Col>
                </Row>
                <Row>
                  <Label lg={4} className="text-left">
                    FBO Workshop
                  </Label>
                  <Col lg={8} className="p-2">
                    <ActionSwitch
                      customClass="mb-4"
                      color="secondary"
                      inputName={`is_fbo`}
                      isChecked={formData?.is_fbo ? true : false}
                      editableForm={disableForm}
                      onChange={handleChangeFBO}
                    />
                  </Col>
                </Row>
                <Row>
                  <Label lg={4} className="text-left">
                    Bengkel Franchise
                  </Label>
                  <Col lg={8} className="p-2">
                    <ActionSwitch
                      customClass="mb-4"
                      color="secondary"
                      inputName={`is_franchise`}
                      isChecked={formData?.is_franchise ? true : false}
                      editableForm={disableForm}
                      onChange={handleChangeFranschise}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Status Bengkel
                  </Label>
                  <Col lg={8} className="col-radio-workshop">
                    <Input
                      type="radio"
                      disabled={true}
                      name="status_active"
                      id="status_active"
                      checked={formData?.status === 'activate' ? true : false}
                      className="radio-workshop cursor-pointer"
                      // onChange={() => handleChangeStatus('activate')}
                    />
                    <span className="ml-2 mr-4 label-radio">{'Aktif'}</span>
                    <Input
                      type="radio"
                      disabled={true}
                      name="status_inactive"
                      id="status_inactive"
                      checked={formData?.status === 'deactivate' ? true : false}
                      className="radio-workshop cursor-pointer"
                      // onChange={() => handleChangeStatus('deactivate')}
                    />
                    <span className="ml-2 mr-4 label-radio">{'Non Aktif'}</span>
                    <Input
                      type="radio"
                      name="status_demo"
                      id="status_demo"
                      disabled={true}
                      checked={formData?.status === 'demo' ? true : false}
                      className="radio-workshop cursor-pointer"
                    />
                    <span className="ml-2 label-radio">{'Demo'}</span>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Tipe Bengkel
                  </Label>
                  <SelectField
                    colWidth={8}
                    name="tier"
                    placeholder="Pilih Tipe Bengkel"
                    closeMenuOnSelect={true}
                    value={formData?.tier}
                    options={tierOptions}
                    disabled={true}
                    isLoading={loadingFetchTierOptions}
                    // onChange={handleChangeTier}
                  />
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Compliance
                  </Label>
                  <SelectField
                    colWidth={8}
                    name="tier"
                    placeholder="Pilih Compliance"
                    closeMenuOnSelect={true}
                    value={formData?.compliance}
                    options={optionsCompliance}
                    disabled={true}
                    // onChange={handleChangeTier}
                  />
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Status MoU
                  </Label>
                  <SelectField
                    colWidth={8}
                    placeholder={'Pilih Status MoU'}
                    disabled={true}
                    closeMenuOnSelect={true}
                    options={optionsMOU}
                    value={formData?.status_mou}
                    // onChange={handleChangeMoU}
                  />
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Nama PIC Otoklix
                  </Label>
                  <InputField
                    colWidth={8}
                    name="pic_otoklix"
                    inputType={'text'}
                    disabled={disableForm}
                    value={formData?.pic_otoklix}
                    invalid={formData?.error_pic_otoklix ? true : false}
                    errorMessage={formData?.error_pic_otoklix ?? ''}
                    placeholder={'Nama PIC Otoklix'}
                    onChange={handleChangePICOtoklix}
                  ></InputField>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <Row className="mb-4">
                  <Label lg={2} className="text-left">
                    Fasilitas
                  </Label>
                  <Col lg={10} className="text-left">
                    {formData?.facilities && formData?.facilities?.length !== 0 && (
                      <Row>
                        {formData?.facilities.map((item) => {
                          return (
                            <Col key={item?.id} md={6} lg={3} className="otopoints-checkbox">
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  className="cursor-pointer"
                                  disabled={disableForm}
                                  checked={item?.is_available ? true : false}
                                  onChange={() => handleChangeFacilities(item)}
                                />
                                <Label
                                  className="text-left ml-2 cursor-pointer"
                                  onClick={() => handleChangeFacilities(item)}
                                >
                                  {item?.label}
                                </Label>
                              </FormGroup>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <Row className="mb-4">
                  <Label lg={2} className="text-left">
                    Capabilities
                  </Label>
                  <Col lg={10} className="text-left">
                    {formData?.capabilities && formData?.capabilities?.length !== 0 && (
                      <Row>
                        {formData?.capabilities.map((item) => {
                          return (
                            <Col key={item?.id} md={6} lg={3} className="otopoints-checkbox">
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  className="cursor-pointer"
                                  disabled={disableForm}
                                  checked={item?.is_available ? true : false}
                                  onChange={() => handleChangeCapabilities(item)}
                                />
                                <Label className="text-left ml-2">{item?.label}</Label>
                              </FormGroup>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12}>
                <Row className="mb-4">
                  <Label lg={2} className="text-left">
                    Description
                  </Label>
                  <InputField
                    colWidth={10}
                    inputType="textarea"
                    disabled={disableForm}
                    value={formData?.description}
                    invalid={formData?.error_description ? true : false}
                    name={'description'}
                    placeholder={'Description'}
                    errorMessage={formData?.error_description ?? ''}
                    onChange={handleChangeDescription}
                  ></InputField>
                </Row>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
};

export default CreateAdvance;
