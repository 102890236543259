import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, Button, CardFooter } from 'reactstrap';
import IconDownload from '@assets/icons/download.svg';
import IconCopy from '@assets/icons/copy.svg';
import IconInfo from '@assets/icons/info.svg';
import userPermission from '@utils/userPermission';
import EstimationPricePdf from '@helpers/pdf/EstimationPricePdf';
import EstimationPriceText from '@helpers/text/EstimationPriceText';
import OfferingLetterPdf from '@helpers/pdf/OfferingLetterPdf';
import OverridePriceIcon from '@assets/icons/override-price.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from 'react-tooltip';
import newTabIcon from '@assets/icons/new-tab.svg';

function BookingDetailBill(props) {
  const { generatePermission } = userPermission();
  const { data, detailData, dataOfferingLetter, title, bookingType, id, onLoading, onResultNotification } = props;

  const downloadEstimatedPrice = () => {
    OfferingLetterPdf.create(data, dataOfferingLetter);
  };

  const handleCopyClipBoard = () => {
    handleResultNotification(true, 'success', 'Estimasi Harga berhasil disalin', true);
  };

  const handleResultNotification = (status = false, type = '', message = '', without_reload = false) => {
    const notification = {
      status: status,
      type: type,
      message: message,
      without_reload: without_reload,
    };

    onResultNotification(notification);
  };

  const estimationText = EstimationPriceText.create(data, detailData);
  const customerType = data?.customer?.customer_type;
  const packages = detailData?.items?.packages;
  const products = detailData?.items?.products;
  const services = detailData?.items?.services;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">{title}</CardHeader>
        <CardBody className="pb-0">
          {bookingType === 'b2c' ? (
            <div className="border border-dark rounded d-flex align-items-center p-2 mb-3 w-fit-content font-weight-bold">
              <img src={IconInfo} alt="" width={16} className="mr-1" />
              <span>Harga yang ditampilkan sudah termasuk PPN</span>
            </div>
          ) : null}

          <FormGroup row className="mb-0">
            <Col lg={12} sm={12}>
              <Row>
                <Col lg={bookingType === 'b2b' ? 3 : 5} sm={12} className="col-summary">
                  {''}
                </Col>
                <Col lg={2} sm={12} className="col-summary">
                  <span className="detail-group price">{'Mark Up'}</span>
                </Col>
                <Col lg={2} sm={12} className="col-summary">
                  <span className="detail-group price">{bookingType === 'b2b' ? 'Disc' : 'Kode Promo'}</span>
                </Col>
                {bookingType === 'b2b' && (
                  <Col lg={2} sm={12} className="col-summary">
                    <span className="detail-group price">{'Voucher'}</span>
                  </Col>
                )}
              </Row>
            </Col>
          </FormGroup>
          {packages?.package_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Paket</span>
                  </Col>
                </Row>
              </Col>

              {map(packages?.package_items, (item) => {
                return (
                  <>
                    {bookingType === 'b2c' ? (
                      <>
                        <Col lg={12} sm={12}>
                          <Row>
                            <Col lg={6} sm={12} className="col-summary">
                              <span className="detail-group">{item?.name ?? '-'}</span>
                            </Col>
                            <Col lg={6} sm={12} className="col-summary">
                              <span className="price detail-group">{`IDR ${Helper.formatMoney(
                                item?.price ?? 0
                              )}`}</span>
                            </Col>
                          </Row>
                        </Col>
                        {map(item?.item_details, (subitem, index) => {
                          return (
                            <Col lg={12} sm={12}>
                              <Row>
                                <Col lg={5} sm={5} className="col-summary">
                                  <>
                                    <span className="detail-group-item-name">
                                      {subitem?.name}
                                      {subitem?.is_fbo && <span className="fbo-badge">FBO</span>}
                                      {subitem?.is_custom && <span className="custom-badge">Custom</span>}
                                    </span>
                                    <span className="detail-group-item-qty">
                                      {`${subitem?.qty} x IDR  ${Helper.formatMoney(subitem?.unit_price ?? 0)}`}
                                      {subitem?.is_overide_sell_price && (
                                        <>
                                          <img
                                            id={`override-icon_${index}`}
                                            src={OverridePriceIcon}
                                            alt=""
                                            width={12}
                                            className="ml-1"
                                          />
                                          <Tooltip
                                            anchorId={`override-icon_${index}`}
                                            place="top"
                                            content="Price is overrided"
                                          />
                                        </>
                                      )}
                                    </span>
                                  </>
                                </Col>
                                <Col lg={2} sm={12} className="col-summary">
                                  <span className="detail-group-item-name grand-total price">
                                    {`${subitem?.markup_percentage ?? 0}%`}
                                  </span>
                                </Col>
                                <Col lg={2} sm={12} className="col-summary">
                                  {bookingType == 'b2b' ? (
                                    <span className="detail-group-item-name discount-otopoints price">
                                      {`${subitem?.discount_percentage ?? 0}%`}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="detail-group discount-otopoints ml-1 font-weight-bold price promo">
                                        {subitem?.voucher_code ?? '-'}
                                      </span>
                                      {!!subitem?.voucher_code && (
                                        <Link
                                          className="text-right"
                                          to={`/campaigns/campaign/detail/${subitem?.campaign_id}`}
                                          target="_blank"
                                        >
                                          <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                                        </Link>
                                      )}
                                    </>
                                  )}
                                </Col>
                                <Col lg={3} sm={12} className="col-summary">
                                  <span className="price detail-group-item-name">
                                    {'IDR ' + Helper.formatMoney(subitem?.price ?? 0)}
                                  </span>
                                  <span className="price detail-group-item-name discount-otopoints">
                                    {'- IDR ' +
                                      Helper.formatMoney(
                                        bookingType == 'b2b'
                                          ? subitem?.discount_amount ?? 0
                                          : subitem.voucher_amount ?? 0
                                      )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <Col lg={12} sm={12}>
                          <Row>
                            <Col lg={bookingType === 'b2b' ? 3 : 5} sm={12} className="col-summary">
                              <span className="detail-group">{item?.name ?? '-'}</span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name grand-total price">
                                {`${item?.markup_percentage ?? 0}%`}
                              </span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name grand-total price">
                                {bookingType == 'b2b' ? (
                                  <span className="detail-group-item-name discount-otopoints price">
                                    {`${item?.discount_percentage ?? 0}%`}
                                  </span>
                                ) : (
                                  <>
                                    <span className="detail-group discount-otopoints ml-1 font-weight-bold price promo">
                                      {item?.voucher_code ?? '-'}
                                    </span>
                                    {!!item?.voucher_code && (
                                      <Link
                                        className="text-right"
                                        to={`/campaigns/campaign/detail/${item?.campaign_id}`}
                                        target="_blank"
                                      >
                                        <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                                      </Link>
                                    )}
                                  </>
                                )}
                              </span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              {bookingType === 'b2b' && (
                                <>
                                  <span className="detail-group-item-name discount-otopoints price min-h-16" />
                                  <span
                                    id={`promo-code-${item?.item_details?.[0]?.voucher_code}`}
                                    className="detail-group-item-name discount-otopoints price text-truncate font-italic"
                                  >
                                    {`${item?.item_details?.[0]?.voucher_code ?? '-'}`}
                                  </span>
                                  <Tooltip
                                    anchorId={`promo-code-${item?.item_details?.[0]?.voucher_code}`}
                                    place="top"
                                    content={item?.item_details?.[0]?.voucher_code}
                                  />
                                  <span className="detail-group-item-name discount-otopoints price">
                                    {`${
                                      item?.item_details?.[0]?.voucher_percentage
                                        ? item?.item_details?.[0]?.voucher_percentage + ' %'
                                        : ''
                                    }`}
                                  </span>
                                </>
                              )}
                            </Col>
                            <Col lg={3} sm={12} className="col-summary">
                              <span className="price detail-group">
                                {`IDR ${Helper.formatMoney(item?.price ?? 0)}`}
                              </span>

                              <span className="price detail-group-item-name discount-otopoints">
                                {`- IDR ${Helper.formatMoney(item?.subtotal_discount ?? 0)}`}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        {map(item?.item_details, (subitem, index) => {
                          return (
                            <Col lg={12} sm={12}>
                              <Row>
                                <Col lg={bookingType === 'b2b' ? 3 : 4} sm={12} className="col-summary">
                                  <>
                                    <span className="detail-group-item-name">
                                      {`${subitem?.qty} x ${subitem?.name}`}
                                      {subitem?.is_fbo && <span className="fbo-badge">FBO</span>}
                                      {subitem?.is_custom && <span className="custom-badge">Custom</span>}
                                    </span>
                                  </>
                                </Col>
                                <Col lg={4} sm={12} className="col-summary" />
                                <Col lg={2} sm={12} className="col-summary" />
                                <Col lg={3} sm={12} className="col-summary">
                                  <span className="price detail-group-item-name">{`IDR ${Helper.formatMoney(
                                    subitem?.price
                                  )}`}</span>
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                        <Col className="col-summary">
                          <span className="remarks-item mb-0">Keterangan :</span>
                          <span className="remarks-item mt-0"> {!item?.note ? '-' : item?.note}</span>
                        </Col>
                      </>
                    )}
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={6} sm={12} className="col-summary">
                          <span className="detail-group">{'Subtotal Paket'}</span>
                        </Col>
                        <Col lg={6} sm={12} className="col-summary">
                          <span className="price detail-group">{`IDR ${Helper.formatMoney(item?.subtotal)}`}</span>
                        </Col>
                      </Row>
                    </Col>
                  </>
                );
              })}
            </FormGroup>
          ) : null}

          {products?.product_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Produk</span>
                  </Col>
                </Row>
              </Col>

              {map(products?.product_items, (item, index) => (
                <Col lg={12} sm={12}>
                  <Row>
                    <Col lg={bookingType === 'b2b' ? 3 : 5} sm={5} className="col-summary">
                      <>
                        <span className="detail-group-item-name">
                          {item?.name}
                          {item?.is_fbo && <span className="fbo-badge">FBO</span>}
                          {item?.is_custom && <span className="custom-badge">Custom</span>}
                        </span>
                        <span className="detail-group-item-qty">
                          {`${item?.qty} x IDR  ${Helper.formatMoney(item?.unit_price ?? 0)}`}
                          {item?.is_overide_sell_price && (
                            <>
                              <img
                                id={`override-icon_${index}`}
                                src={OverridePriceIcon}
                                alt=""
                                width={12}
                                className="ml-1"
                              />
                              <Tooltip anchorId={`override-icon_${index}`} place="top" content="Price is overrided" />
                            </>
                          )}
                        </span>
                        {item?.remark && (
                          <>
                            <span className="remarks-item mb-0">{'Keterangan :'}</span>
                            <span className="remarks-item mt-0">
                              {!item?.remark ? '-' : item?.remark.replace(/-/g, '‑')}
                            </span>
                          </>
                        )}
                      </>
                    </Col>
                    <Col lg={2} sm={12} className="col-summary">
                      <span className="detail-group-item-name grand-total price">
                        {`${item?.markup_percentage ?? 0}%`}
                      </span>
                    </Col>
                    <Col lg={2} sm={12} className="col-summary">
                      {bookingType == 'b2b' ? (
                        <>
                          <span className="detail-group-item-name discount-otopoints price min-h-16" />
                          <span className="detail-group-item-name discount-otopoints price">
                            {`${item?.discount_percentage ?? 0}%`}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="detail-group discount-otopoints ml-1 font-weight-bold price promo">
                            {item?.voucher_code ?? '-'}
                          </span>
                          {!!item?.voucher_code && (
                            <Link
                              className="text-right"
                              to={`/campaigns/campaign/detail/${item?.campaign_id}`}
                              target="_blank"
                            >
                              <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                            </Link>
                          )}
                        </>
                      )}
                    </Col>
                    {bookingType === 'b2b' && (
                      <Col lg={2} sm={12} className="col-summary">
                        <span className="detail-group-item-name discount-otopoints price min-h-16" />
                        <span className="detail-group-item-name discount-otopoints price min-h-16" />
                        <span
                          id={`promo-code-product-${index}-${item?.voucher_code}`}
                          className="detail-group-item-name discount-otopoints price text-truncate font-italic"
                        >
                          {`${item?.voucher_code ?? '-'}`}
                        </span>
                        <Tooltip
                          anchorId={`promo-code-product-${index}-${item?.voucher_code}`}
                          place="top"
                          content={item?.voucher_code}
                        />
                        <span className="detail-group-item-name discount-otopoints price">
                          {`${item?.voucher_percentage ? item?.voucher_percentage + ' %' : ''}`}
                        </span>
                      </Col>
                    )}
                    <Col lg={3} sm={12} className="col-summary">
                      <span className="price detail-group-item-name">
                        {'IDR ' + Helper.formatMoney(item?.price ?? 0)}
                      </span>
                      <span className="price detail-group-item-name discount-otopoints">
                        {'- IDR ' +
                          Helper.formatMoney(
                            bookingType == 'b2b' ? item?.discount_amount ?? 0 : item?.voucher_amount ?? 0
                          )}
                      </span>
                      {bookingType === 'b2b' && (
                        <span className="price detail-group-item-name discount-otopoints">
                          {'- IDR ' + Helper.formatMoney(item?.voucher_amount ?? 0)}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              ))}

              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="detail-group">Subtotal Produk</span>
                  </Col>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="price detail-group">{`IDR ${Helper.formatMoney(
                      products?.subtotal_product ?? 0
                    )}`}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null}

          {services?.service_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Jasa</span>
                  </Col>
                </Row>
              </Col>

              {map(services?.service_items, (item, index) => (
                <Col lg={12} sm={12}>
                  <Row>
                    <Col lg={bookingType === 'b2b' ? 3 : 5} sm={5} className="col-summary">
                      <>
                        <span className="detail-group-item-name">
                          {item?.name}
                          {item?.is_fbo && <span className="fbo-badge">FBO</span>}
                          {item?.is_custom && <span className="custom-badge">Custom</span>}
                        </span>
                        <span className="detail-group-item-qty">
                          {`${item?.qty} x IDR  ${Helper.formatMoney(item?.unit_price ?? 0)}`}
                          {item?.is_overide_sell_price && (
                            <>
                              <img
                                id={`override-icon_${index}`}
                                src={OverridePriceIcon}
                                alt=""
                                width={12}
                                className="ml-1"
                              />
                              <Tooltip anchorId={`override-icon_${index}`} place="top" content="Price is overrided" />
                            </>
                          )}
                        </span>
                        {item?.remark && (
                          <>
                            <span className="remarks-item mb-0">{'Keterangan :'}</span>
                            <span className="remarks-item mt-0">
                              {!item?.remark ? '-' : item?.remark.replace(/-/g, '‑')}
                            </span>
                          </>
                        )}
                      </>
                    </Col>
                    <Col lg={2} sm={12} className="col-summary">
                      <span className="detail-group-item-name grand-total price">
                        {`${item?.markup_percentage ?? 0}%`}
                      </span>
                    </Col>
                    <Col lg={2} sm={12} className="col-summary">
                      {bookingType == 'b2b' ? (
                        <>
                          <span className="detail-group-item-name discount-otopoints price min-h-16" />
                          <span className="detail-group-item-name discount-otopoints price">
                            {`${item?.discount_percentage ?? 0}%`}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="detail-group discount-otopoints ml-1 font-weight-bold price promo">
                            {item?.voucher_code ?? '-'}
                          </span>
                          {!!item?.voucher_code && (
                            <Link
                              className="text-right"
                              to={`/campaigns/campaign/detail/${item?.campaign_id}`}
                              target="_blank"
                            >
                              <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                            </Link>
                          )}
                        </>
                      )}
                    </Col>
                    {bookingType === 'b2b' && (
                      <Col lg={2} sm={12} className="col-summary">
                        <span className="detail-group-item-name discount-otopoints price min-h-16" />
                        <span className="detail-group-item-name discount-otopoints price min-h-16" />
                        <span
                          id={`promo-code-service-${index}-${item?.voucher_code}`}
                          className="detail-group-item-name discount-otopoints price text-truncate font-italic"
                        >
                          {`${item?.voucher_code ?? '-'}`}
                        </span>
                        <Tooltip
                          anchorId={`promo-code-service-${index}-${item?.voucher_code}`}
                          place="top"
                          content={item?.voucher_code}
                        />
                        <span className="detail-group-item-name discount-otopoints price">
                          {`${item?.voucher_percentage ? item?.voucher_percentage + ' %' : ''}`}
                        </span>
                      </Col>
                    )}
                    <Col lg={3} sm={12} className="col-summary">
                      <span className="price detail-group-item-name">
                        {'IDR ' + Helper.formatMoney(item?.price ?? 0)}
                      </span>
                      <span className="price detail-group-item-name discount-otopoints">
                        {'- IDR ' +
                          Helper.formatMoney(
                            bookingType == 'b2b' ? item?.discount_amount ?? 0 : item?.voucher_amount ?? 0
                          )}
                      </span>
                      {bookingType === 'b2b' && (
                        <span className="price detail-group-item-name discount-otopoints">
                          {'- IDR ' + Helper.formatMoney(item?.voucher_amount ?? 0)}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              ))}

              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="detail-group">Subtotal Jasa</span>
                  </Col>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="price detail-group">{`IDR ${Helper.formatMoney(
                      services?.subtotal_service ?? 0
                    )}`}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null}

          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col lg={6} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{'DPP'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price detail-group">{`IDR ${Helper.formatMoney(detailData?.subtotal ?? 0)}`}</span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col lg={6} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{'Total'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price detail-group">{`IDR ${Helper.formatMoney(detailData?.grand_total ?? 0)}`}</span>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormGroup row className="mb-0">
            <Col lg={6} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">
                    {bookingType === 'b2b' ? 'PPN' : 'Promo Whole Cart'}
                    <span className="detail-group ml-3">{data?.discount?.promo ?? ''}</span>
                    <span className="detail-group-promo-name ml-1">
                      {data?.discount?.promo ? (
                        <span>
                          {'( ' + data?.discount?.name ?? '-'}
                          <Link to={`/campaigns/campaign/detail/${data?.discount?.id}`} target="_blank">
                            <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                          </Link>
                          {' )'}
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className={`price${bookingType === 'b2b' ? '' : ' discount-otopoints'}`}>
                    {`${bookingType === 'b2b' ? '' : '- '}IDR ${Helper.formatMoney(
                      bookingType === 'b2b' ? detailData?.ppn : data?.discount?.amount ?? 0
                    )}`}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col lg={6} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{bookingType === 'b2b' ? 'PPH' : 'Otopoints'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price discount-otopoints">
                    {bookingType === 'b2b'
                      ? `- IDR ${Helper.formatMoney(detailData?.pph ?? 0)}`
                      : `${data?.otopoints?.point_spent < 1 ? '- ' : ''}IDR ${Helper.formatMoney(
                          data?.otopoints?.point_spent ?? 0
                        )}`}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          {bookingType === 'b2c' && (
            <>
              <FormGroup row className="mb-2">
                <Col lg={6} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-left col-summary">
                      <span className="group">{'Total'}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="price detail-group">{`IDR ${Helper.formatMoney(detailData?.amount ?? 0)}`}</span>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>

              <FormGroup row className="my-0">
                <Col lg={12} sm={12} className="col-summary">
                  <div className="divider"></div>
                </Col>
              </FormGroup>
            </>
          )}

          {bookingType === 'b2b' ? (
            <>
              <FormGroup row className="mb-0">
                <Col lg={6} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-left col-summary">
                      <span className="group">Amount</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="price detail-group">{'IDR ' + Helper.formatMoney(detailData?.amount ?? 0)}</span>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Col lg={12} sm={12} className="col-summary">
                  <div className="divider"></div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col lg={6} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-left col-summary">
                      <span className="group">Total Diskon</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="price detail-group">
                        {'IDR ' + Helper.formatMoney(detailData?.discount ?? 0)}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </>
          ) : (
            <></>
          )}

          {bookingType === 'b2c' ? (
            <>
              <FormGroup row className="mb-2 text-muted font-weight-bold">
                <Col lg={6} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-left">
                      <span className="group">{'Final DPP'}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="price">{'IDR ' + Helper.formatMoney(detailData?.dpp ?? 0)}</span>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup row className="text-muted font-weight-bold">
                <Col lg={6} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-left">
                      <span className="group">{'Final PPN'}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="price">{'IDR ' + Helper.formatMoney(detailData?.ppn ?? 0)}</span>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </>
          ) : null}
        </CardBody>

        {customerType == 'b2b' && generatePermission('booking', 'estimatedprice') && (
          <CardFooter className="p-3">
            <Row>
              <Col lg={6} md={12} sm={12} className="mt-2">
                <Button color="purple" block onClick={downloadEstimatedPrice}>
                  <img src={IconDownload} className="mr-2 mb-1" />
                  <span>Download Surat Penawaran</span>
                </Button>
              </Col>
              <Col lg={6} md={12} sm={12} className="mt-2">
                <CopyToClipboard onCopy={handleCopyClipBoard} text={estimationText}>
                  <Button color="purple" block>
                    <img src={IconCopy} className="mr-2 mb-1" />
                    <span>Salin Estimasi Harga</span>
                  </Button>
                </CopyToClipboard>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailBill;
